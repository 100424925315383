<template>
  <div>
    <navbar :dark="true"></navbar>
    <slot />
  </div>
</template>
<script>
import Navbar from '@/components/navbar/full-navbar'
export default {
  components: {
    Navbar
  }
}
</script>

