<template>
  <div>
    <div
      class="modal"
      :class="{'is-active': modal}"
    >
      <div class="modal-background"></div>
      <div class="modal-content">
        <p class="image is-4by3">
          <img :src="focus">
        </p>
      </div>
      <button
        class="modal-close"
        @click="toggle()"
      ></button>
    </div>

    <div v-if="dataLoaded">
      <div class="columns is-gapless is-full-height reverse-row-order">
        <track-aside :track="track"></track-aside>
        <div class="column">
          <section class="section">
          <track-stat :track="track"></track-stat>
          <map-detail
            class="block"
            :pois="pois"
            :track-id="track.id"
          ></map-detail>
          <elevation
            :track-id="track.id"
            class="block is-hidden-mobile"
          ></elevation>
          <picture-gallery :pictures="track.pictures"></picture-gallery>
          <track-video :video="track.video"></track-video>
          <comments
            :comments="track.comments"
            :track-id="track.id"
          ></comments>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapDetail from '@/components/map-detail'
import elevation from './elevation-graph'
import filters from '@/mixins/filters'
import api from '@/mixins/api'
import seo from '@/mixins/seo'
import Video from '../../services/video-link-generator'
import BaguetteBox from 'baguettebox.js'
import 'baguettebox.js/dist/baguetteBox.css'
import TrackStat from './track-stat'
import PictureGallery from './pictures-gallery'
import TrackVideo from './track-video'
import Comments from './track-comments'
import TrackAside from './track-aside'

const simulateClick = function (elem) {
  // Create our event (with options)
  const evt = new window.MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  })
  // If cancelled, don't dispatch our event
  !elem.dispatchEvent(evt)
}

const getTitle = function (track) {
  let prefix = ''
  if (track.rating.sport) {
    if (track.rating.sport === 'mountain bike') {
      prefix = 'Parcours VTT'
    } else if (track.rating.sport === 'hiking / running') {
      prefix = 'Parcours de Trail'
    }
  }
  return `${prefix} à ${track.startAddress.city} - ${track.name}`
}

export default {
  mixins: [api, filters, seo],
  created () {
    const id = this.$route.params.id
    this.$on('commentAdded', () => {
      this.loadTrackData(id)
    })
  },
  data () {
    return {
      track: {
        comments: [],
        pictures: [],
        video: []
      },
      dataLoaded: false,
      focus: '',
      modal: false
    }
  },
  components: {
    mapDetail,
    elevation,
    TrackStat,
    PictureGallery,
    TrackVideo,
    Comments,
    TrackAside
  },
  methods: {
    loadTrackData (id) {
      this.get('tracks/' + id).then(response => {
        this.track = response.data
        this.setTitle(getTitle(this.track))
        this.setDescription(this.track.description)
        this.track.description = response.data.description.replace(/\n/g, '<br>')
        this.track.pictures = response.data.pictures.map(el => {
          el.thumbnail = process.env.VUE_APP_RESIZE + '200/200/' + el.url
          el.url = process.env.VUE_APP_RESIZE + 'pics/' + el.url
          return el
        })
        if (this.track.video.length > 0) {
          let v = this.track.video[0]
          this.track.video = Video.getLink(v.platform, v.slug)
        }
        this.dataLoaded = true

        // @TODO Fix this ugly hack
        setTimeout(function () {
          BaguetteBox.run('.gallery')
        }, 100)
      })
    }
  },
  mounted () {
    let id = this.$route.params.id
    this.loadTrackData(id)
  },
  computed: {
    pois () {
      const pix = this.track.pictures.filter(x => x.position !== null).map(x => {
        return {
          ...x,
          icon: 'fa-camera',
          action () {
            const p = document.getElementById(x.id)
            simulateClick(p)
          }
        }
      })

      const pois = this.track.pois.map(x => {
        if (x.icon === '') {
          x.icon = 'fa-map-marker-alt'
        }
        return x
      })

      return [...pois, ...pix]
    }
  }
}
</script>
<style scoped>
.margin {
  margin-top: 15px;
}
.right {
  text-align: right;
}
.aside {
  background-color: #fbfbfb;
  border-right: 1px solid #f1f1f1;
}
</style>