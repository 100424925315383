<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <ul>
            <li>
              <router-link to="/">
                {{$t('footer.home')}}
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'home', query: {login: true}}">
                {{$t('footer.login')}}
              </router-link>
            </li>
          </ul>
          <hr />
          <ul>
            <li><a
                href="http://twitter.com/latracepointcom"
                target="_blank"
                class="twitter"
              >{{$t('footer.twitter')}}</a></li>
            <li><a
                href="http://www.facebook.com/pages/Latrace/146955245363923"
                class="facebook"
                target="_blank"
              >{{$t('footer.facebook')}}</a></li>
          </ul>
          <hr />
          <ul class="tSmall">
            <li><a
                href="http://fr.wikipedia.org/wiki/GPX_(format_de_fichier)"
                target="_blank"
                class="tSmall"
              >{{$t('footer.gpx')}}</a></li>
            <li><a
                href="http://www.gpsbabel.org/"
                target="_blank"
                class="tSmall"
              >{{$t('footer.gpsBabel')}}</a></li>
          </ul>
          <hr />
          <locale-changer></locale-changer>
          <hr />
          <ul class="tSmall">
            <li>Version: {{version}}</li>
          </ul>
        </div>

        <div class="column">
          <article
            class="media is-size-6"
            v-for="track in lastTracks"
          >
            <figure class="media-left">
              <p class="image is-32x32">
                <img src="../../assets/map-32.png">
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <router-link :to="{name: 'track.detail', params: {id: track.id}}">
                  {{track.name}} <br> {{track.distance | toKm}} km {{track.elevationGain}} m
                </router-link>
              </div>
            </div>
          </article>
        </div>

        <div class="column">
          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input
                class="input"
                type="email"
                :placeholder="$t('footer.email')"
                v-model="contact.email"
              >
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right">
                <i class="fas fa-check"></i>
              </span>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <textarea
                class="textarea"
                :placeholder="$t('footer.yourMessage')"
                v-model="contact.content"
              ></textarea>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button
                class="button is-primary"
                @click="sendContact"
              >{{$t('footer.sendMessage')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import api from '@/mixins/api'
import filters from '@/mixins/filters'
import LocaleChanger from '../locale-changer/locale-changer'

const contactForm = {
  email: '',
  content: ''
}

export default {
  mixins: [api, filters],
  components: {
    LocaleChanger
  },
  methods: {
    resetContact () {
      this.contact = {
        email: '',
        content: ''
      }
    },
    sendContact () {
      const data = { email: this.contact.email, content: this.contact.content }
      this.post('contact', data).then(response => {
        this.$buefy.toast.open({
          message: 'Message sent successfully!',
          type: 'is-success'
        })
        this.resetContact()
      })
    }
  },
  data () {
    return {
      contact: contactForm,
      version: process.env.COMMIT,
      lastTracks: []
    }
  },
  mounted () {
    this.get('/last').then(data => {
      this.lastTracks = data.data
    })
  }
}
</script>
<style scoped>
.media {
  border: none !important;
  padding-top: 0.5rem !important;
  margin-top: 0rem !important;
}
.content {
  color: #fff !important;
}
section {
  background-color: #192532dd;
  /* padding: 20px 0; */
  color: #fff;
  min-height: 400px;
}
a,
a:hover {
  color: #fff;
}
</style>