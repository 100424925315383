<template>
  <div>
    <div class="container">
      <div class="notification is-danger">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/mixins/api'
import { mapActions } from 'vuex'

export default {
  mixins: [api],
  data () {
    return {
      code: '',
      loader: null,
      error: null
    }
  },
  methods: {
    ...mapActions(['storeToken', 'login']),
    getToken () {
      this.post('jwt', { code: this.code }).then(response => {
        this.loader.close()
        const token = response.data
        this.storeToken(token)
        this.login()
        this.$router.push({ path: '/' })
      }).catch(e => {
        this.loader.close()
        if (e.response.status === 401) {
          this.error = this.$t('magiclink.codeHasExpired')
        } else if (e.response.status === 404) {
          this.error = this.$t('magiclink.codeDoesntExist')
        }
      })
    }
  },
  mounted () {
    this.loader = this.$buefy.loading.open({
      container: null
    })
    this.code = this.$route.params.code
    this.getToken()
  }
}
</script>
