<template>
  <section class="section">
    <div class="container">
      <p>Member since the {{user.creation}}</p>
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="user.name"
          >
        </div>
      </div>
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input
            class="input"
            type="email"
            v-model="user.email"
          >
          <p
            class="help is-danger"
            v-if="!emailValid"
          >{{ $t('register.emailNotValid') }}</p>
        </div>
      </div>

      <a
        class="button is-info is-fullwidth"
        @click="updateProfile()"
      >Save</a>

      <hr>
      <div class="field">
        <label class="label">Password</label>
        <div class="control">
          <input
            class="input"
            type="password"
            v-model="password"
          >
        </div>
        <p
          class="help is-info"
          v-if="passwordNotCompliant"
        >{{ $t('register.passwordSize') }}</p>

      </div>
      <div class="field">
        <label class="label">Retype Password</label>
        <div class="control">
          <input
            class="input"
            type="password"
            v-model="passwordCheck"
          >
        </div>
        <p
          class="help is-danger"
          v-if="passwordNotMatch"
        >{{ $t('register.passwordDoesntMatch') }}</p>

      </div>
      <a
        class="button is-warning is-fullwidth"
        @click="updatePassword()"
        :disabled="!isPasswordValid()"
      >Update Password</a>
    </div>
  </section>
</template>
<script>
import api from '@/mixins/api'
import filters from '@/mixins/filters'
export default {
  computed: {
    emailValid () {
      return this.validateEmail(this.user.email)
    },
    passwordNotCompliant () {
      return this.password.length < 8
    },
    passwordNotMatch () {
      if (this.password !== '') {
        return this.password !== this.passwordCheck
      }
      return false
    },
    passwordOk () {
      return !this.passwordNotMatch && !this.asswordNotCompliant && this.isPasswordValid
    },
    isValid () {
      if (!this.emailValid) {
        return false
      }
      return true
    }
  },
  mixins: [api, filters],
  data () {
    return {
      user: {},
      password: '',
      passwordCheck: ''
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    validateEmail (email) {
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/ig
      return re.test(email)
    },
    isPasswordValid () {
      if (this.password === '') {
        return false
      }
      if (this.password !== this.passwordCheck) {
        return false
      }
      return true
    },
    getData () {
      this.get('users/me').then(response => {
        this.user = response.data
      })
    },
    updatePassword () {
      if (this.passwordOk) {
        this.put('users/me/password', { 'password': this.password }).then(response => {
          this.$buefy.toast.open({
            message: this.$t('common.operation.success'),
            type: 'is-info'
          })
        })
      }
    },
    updateProfile () {
      this.patch('users/me', { 'name': this.user.name, 'email': this.user.email }).then(response => {
        this.getData()
        this.$buefy.toast.open({
          message: this.$t('common.operation.success'),
          type: 'is-info'
        })
      })
    }
  }
}
</script>
