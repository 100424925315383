<template>
  <nav
    class="navbar"
    v-bind:class="{'menu-dark' : dark === true}"
  >
    <div class="container">

      <div class="navbar-brand">
        <router-link
          to="/"
          class="navbar-item is-link logo white"
        ><span class="white">la-</span><span class="green">trace</span><span class="white">.com</span></router-link>
      </div>

      <div
        id="navbarMenu"
        class="navbar-menu"
      >
        <span
          class="navbar-item"
          v-if="!isLogged()"
        >
          <a
            class="button is-white is-outlined"
            @click="login()"
          >
            <span class="icon">
              <i class="fas fa-user"></i>
            </span>
            <span>{{ $t('menu.login') }}</span>
          </a>
        </span>

        <span
          class="navbar-item"
          v-if="!isLogged()"
        >
          <router-link
            :to="{ name: 'register' }"
            class="button is-white is-outlined"
          >
            <span class="icon">
              <i class="fas fa-user"></i>
            </span>
            <span>{{ $t('menu.register') }}</span>
          </router-link>
        </span>

        <div class="navbar-end">
          <dropdown v-if="isLogged()"></dropdown>
        </div>

      </div>
    </div>
  </nav>
</template>
<script>
import Login from '@/components/login/login-modal'
import dropdown from '@/components/navbar/user-dropdown'
import { mapGetters } from 'vuex'

export default {
  components: { dropdown },
  props: ['dark'],
  methods: {
    ...mapGetters(['isLogged']),
    login () {
      this.$buefy.modal.open({
        component: Login,
        parent: this,
        hasModalCard: true
      })
    }
  }
}
</script>
<style scoped>
.white {
  color:#FFF !important;
}
</style>