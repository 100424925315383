<template>
  <div class="cover">
    <section class="section">
      <!-- <video
      poster=""
      id="bgvid"
      playsinline
      autoplay
      muted
      loop
    >
      <source src="https://r2---sn-5hne6nsd.googlevideo.com/videoplayback?expire=1593386183&ei=Z9D4XpjMIsqogAfz8LWgAw&ip=45.72.32.153&id=o-AJGVSFxg_UuLsvkmICSnau0yKVkxDh6N-yRyYugQR5SJ&itag=303&aitags=133%2C134%2C135%2C136%2C137%2C160%2C242%2C243%2C244%2C247%2C248%2C278%2C298%2C299%2C302%2C303&source=youtube&requiressl=yes&vprv=1&mime=video%2Fwebm&gir=yes&clen=109686714&dur=183.060&lmt=1562908600401184&fvip=2&keepalive=yes&beids=23886220&c=WEB&txp=2206222&sparams=expire%2Cei%2Cip%2Cid%2Caitags%2Csource%2Crequiressl%2Cvprv%2Cmime%2Cgir%2Cclen%2Cdur%2Clmt&sig=AOq0QJ8wRAIgcSW1HW28o5H90wUUJXCt_oAsMAAsp5BuBVCdWfTTPWQCIGlmlSDMv7FViRvgau_Hg5Wtl4hBGd2-_ubj5-Zcq6T-&video_id=7bihOZ6NWm0&title=infinite+loop+%28la-trace.com%29&redirect_counter=1&cm2rm=sn-n4vl67e&fexp=23886220&req_id=2096ce994849a3ee&cms_redirect=yes&mh=wi&mip=92.188.151.124&mm=34&mn=sn-5hne6nsd&ms=ltu&mt=1593368881&mv=u&mvi=1&pl=18&lsparams=mh,mip,mm,mn,ms,mv,mvi,pl&lsig=AG3C_xAwRAIgNjAdZLur4Pv2aftiETjrJAGs_bScAFFQCv-8tj0W4UACIEfR9GHGGMJLDYKZDY69hdyKcRNMywggWoVvWLnOgj-j">
    </video> -->
      <navbar></navbar>
      <div class="container">
        <div class="columns">
          <div class="column is-6 is-offset-3 has-text-centered">
            <h1 class="title white padded"></h1>
            <h2 class="subtitle">{{ $t('home.baseline') }}</h2>
            <div class="box">
              <div class="field is-grouped">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    v-model="search"
                    @keyup.enter="search"
                    :placeholder="$t('menu.keywordSearch')"
                  >
                  <!-- <b-autocomplete
                    v-model="search"
                    size="is-medium"
                    :data="results"
                    :placeholder="$t('home.searchByKeyword')"
                    @input="query"
                    icon="search is-small"
                    icon-pack="fa"
                    @select="option => selected(option)"
                  >
                    <template slot-scope="props">
                      <div class="media">
                        <div class="media-content">
                          {{ props.option.name }}
                        </div>
                      </div>
                    </template>
                  </b-autocomplete> -->
                </p>
                <!--p class="control is-desktop">
                  <a
                    class="button is-info is-medium"
                    @click="goSearch"
                  >{{ $t('home.search') }}</a>
                </p-->
              </div>
              <p class="control is-mobile">
                <a
                  class="button is-info is-medium is-fullwidth"
                  @click="goSearch"
                >{{ $t('home.search') }}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="columns is-hidden-touch">
          <div class="column is-half">
            <home-card-action
              color="#606F86"
              :title="$t('home.search')"
              :image="require('../../assets/map-search.jpg')"
              :subtitle="$t('home.findOnAMap')"
              route-name="search.map"
            />
          </div>
          <div class="column is-half">
            <home-card-action
              color="#4393DB"
              :image="require('../../assets/map-draw.jpg')"
              :title="$t('home.create')"
              :subtitle="$t('home.createTrack')"
              :route-name="create"
            />
          </div>
        </div>

        <div
          class="columns"
          id="starred"
        >
          <div
            class="column is-one-quarter"
            v-for="track in starred"
          >
            <home-card :track="track"></home-card>
          </div>
        </div>
      </div>
    </section>
    <site-footer></site-footer>
  </div>
</template>

<script>
import filters from '@/mixins/filters'
import tools from '@/mixins/tools'
import api from '@/mixins/api'
import seo from '@/mixins/seo'
import SiteFooter from './footer'
import debounce from 'lodash/debounce'
import Navbar from '../navbar/navbar'
import HomeCard from './home-card'
import HomeCardAction from './home-card-action'
import Login from '@/components/login/login-modal'

const shuffle = arr => {
  if (arr.length === 1) { return arr }
  const rand = Math.floor(Math.random() * arr.length)
  return [arr[rand], ...shuffle(arr.filter((_, i) => i !== rand))]
}

export default {
  components: {
    SiteFooter,
    HomeCard,
    HomeCardAction,
    Navbar
  },
  watch: {
    $route (to, from) {
      if (this.$route.query.login) {
        this.login()
      }
    }
  },
  mounted () {
    this.getStarred()
    if (this.$route.query.login) {
      this.login()
    }
  },
  mixins: [filters, api, tools, seo],
  data () {
    return {
      needLogin: this.$route.query.login,
      search: '',
      starred: [],
      results: []
    }
  },
  methods: {
    login () {
      this.$buefy.modal.open({
        component: Login,
        parent: this,
        hasModalCard: true
      })
    },
    create () {
      this.wrapModalWithTargetRoute({ name: 'prepare' })
    },
    selected (track) {
      if (track) {
        this.$router.push({ name: 'track.detail', params: { id: track.id } })
      }
    },
    getStarred () {
      this.get('tracks/starred').then(resp => {
        if (resp.data.length > 0) {
          this.starred = shuffle(resp.data).slice(0, 4)
        } else {
          this.starred = resp.data
        }
      })
    },
    goSearch () {
      this.$router.push({ name: 'search.keyword', query: { q: this.search } })
    },
    query: debounce(function () {
      const params = { q: this.search }
      this.get('search', { params: params }).then(resp => {
        let data = resp.data
        this.results = data
      })
    }, 400)
  }
}
</script>
<style scoped>
/* #background {
  height: 500px;
  background: url(../../assets/vtt-1.jpg) no-repeat center center fixed;
  background-size: cover;
} */

/* .search {
  margin-top: 150px;
} */

/* .baseline {
  padding: 2rem 0;
  margin-bottom: 1rem;
} */

/* #starred {
  margin-top: 65px;
} */

h2 {
  font-size: 30px !important;
  color: #fff;
}

.padded {
  margin-top: 120px;
}

video {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
}

.white {
  color: #fff;
}

#home-search-results {
  background: rgba(256, 256, 256, 0.7);
  padding: 50px;
}

.cover {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/bike.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.navbar {
  background-color: transparent !important;
}
</style>