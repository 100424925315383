import { render, staticRenderFns } from "./need-login.vue?vue&type=template&id=6cb3551e&scoped=true&"
var script = {}
import style0 from "./need-login.vue?vue&type=style&index=0&id=6cb3551e&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb3551e",
  null
  
)

export default component.exports