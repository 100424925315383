<template>
  <b-tooltip
    :label="tooltip"
    class="is-success"
    multilined
  >
    <a
      class="button is-primary"
      :disabled="isDisabled"
      @click="action()"
    >
      <span class="icon">
        <i
          class="fas"
          v-bind:class="icon"
        ></i>
      </span>
    </a>
  </b-tooltip>
</template>
<script>
export default {
  props: ['isDisabled', 'tooltip', 'action', 'icon']
}
</script>
