<template>
  <section class="section">
    <form
      action=""
      v-on:submit.prevent
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered is-size-5">{{ $t('login.login') }}</p>
        </header>

        <section class="modal-card-body">
          <div
            class="notification is-success animated fadeIn"
            v-if="result.length > 0"
          >
            {{ result }}
          </div>

          <div
            class="notification is-danger animated fadeIn"
            v-if="error.length > 0"
          >
            {{ error }}
          </div>

          <div v-if="mode == 'magic'">
            <b-field :label="$t('login.email')">
              <b-input
                type="email"
                :placeholder="$t('login.yourEmail')"
                autocomplete="email"
                v-model="email"
              >
              </b-input>
            </b-field>
            <button
              class="button is-info is-fullwidth"
              :class="{'is-loading': isLoading}"
              @click="magicLink()"
            >
              <span class="icon">
                <i class="fas fa-paper-plane"></i>
              </span>
              <span>{{ $t('login.sendMagicLink') }}</span>
            </button>

            <p
              style="padding-top:15px"
              class="is-size-6"
            >{{ $t('login.explain')}} <a @click="mode = 'password'">{{ $t('login.here')}}</a>.</p>
          </div>

          <div v-if="mode == 'password'">

            <b-field :label="$t('login.email')">
              <b-input
                type="email"
                :placeholder="$t('login.yourEmail')"
                v-model="email"
                autocomplete="email"
              >
              </b-input>
            </b-field>

            <b-field :label="$t('login.password')">
              <b-input
                type="password"
                :placeholder="$t('login.yourPassword')"
                v-model="password"
                autocomplete="password"
              >
              </b-input>
            </b-field>
            <button
              class="button is-info is-fullwidth"
              :class="{'is-loading': isLoading}"
              @click="login()"
            >
              <span class="icon">
                <i class="fas fa-sign-in-alt"></i>
              </span>
              <span>{{ $t('login.signIn') }}</span>
            </button>

            <p
              style="padding-top:15px"
              class="is-size-6"
            >
              {{ $t('login.ifYouPreferMagicLink') }} <a @click="mode = 'magic'">{{ $t('login.here') }}</a>.</p>
          </div>

        </section>
        <footer class="modal-card-foot">
          <p class="is-size-7"> {{ $t('login.noAccountYet') }} <a @click="toRegister">{{ $t('login.signUp') }}.</a> </p>
        </footer>
      </div>
    </form>
  </section>
</template>

<script>
import api from '@/mixins/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [api],
  methods: {
    ...mapGetters(['getTargetPath']),
    ...mapActions(['signIn']),
    magicLink () {
      this.isLoading = true
      this.post('magic-link', { email: this.email }).then(response => {
        this.result = this.$t('login.success')
        this.isLoading = false
        this.email = ''
      }).catch(e => {
        this.isLoading = false
        this.error = this.$t('common.error')
      })
    },
    toRegister () {
      this.$emit('close')
      this.$router.push({ name: 'register' })
    },
    login () {
      this.isLoading = true
      this.signIn({ email: this.email, password: this.password }).then(response => {
        this.isLoading = false
        this.$emit('close')
        const target = this.getTargetPath()
        if (target.name) {
          this.$router.push({ name: target.name, params: target.params })
        } else {
          this.$router.push({ path: '/' })
        }
        this.$buefy.toast.open({
          message: 'Welcome !',
          type: 'is-success'
        })
      }).catch(e => {
        this.isLoading = false
        if (!e.response) {
          this.error = this.$t('common.operation.error')
          return
        }

        if (e.response.status === 401) {
          this.error = this.$t('login.failed')
        } else if (e.response.status === 403) {
          this.error = this.$t('login.accountInactive')
        } else {
          this.error = this.$t('common.operation.error')
        }
      })
    }
  },
  data () {
    return {
      mode: 'password',
      isLoading: false,
      password: '',
      email: '',
      result: '',
      error: ''
    }
  }
}
</script>
<style scoped>
.modal-card-foot,
.modal-card-head {
  background-color: #fff !important;
}
</style>
