import haversine from 'haversine'

export default function name (geojson) {
  const values = []
  var buffer = 0
  geojson.geometry.coordinates.forEach((current, index, array) => {
    if (index === 0) {
      values.push(0)
      return
    }
    let previous = array[index - 1]
    let currentPoint = {longitude: current[0], latitude: current[1]}
    let previousPoint = {longitude: previous[0], latitude: previous[1]}
    let value = haversine(currentPoint, previousPoint, {unit: 'meter'})
    buffer = buffer + value
    values.push(buffer)
  })
  return values
}
