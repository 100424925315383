<template>
  <div>
    <h3 class="title">
      <span class="icon is-medium">
        <i class="fas fa-search"></i>
      </span>
      {{ headtitle }}
    </h3>
    <table class="table is-bordered is-fullwidth">
      <thead>
        <tr>
          <th>{{ $t('search.name') }}</th>
          <th>{{ $t('search.elevationGain') }}</th>
          <th>{{ $t('search.distance') }}</th>
          <th v-if="displayDownloads">{{ $t('search.distance') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="track in tracks">
          <td>
            <router-link :to="{name: 'track.detail', params: {id: track.id}}">
              {{ track.name }}
            </router-link>
          </td>
          <td>{{ track.elevationGain }} m</td>
          <td>{{ track.distance | toKm }} km</td>
          <td v-if="displayDownloads">{{ track.downloads }}</td>
        </tr>
        <tr v-if="tracks.length === 0">
          <td colspan="4">{{ $t('search.noResults') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import filters from '@/mixins/filters'

export default {
  mixins: [filters],
  computed: {
    headtitle () {
      if (this.title === '') {
        return this.$t('search.results')
      } else {
        return this.title
      }
    }
  },
  props: {
    tracks: Array,
    title: {
      type: String,
      default: ''
    },
    displayDownloads: {
      type: Boolean,
      default: false
    }
  }
}
</script>
