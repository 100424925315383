<template>
  <div>
    <label
      for="file"
      class="label-file button is-primary"
      v-bind:class="{'is-loading': processing, 'is-fullwidth': fullwidth}"
      id="upload-button"
    >
      <span class="icon is-medium">
        <i class="fas fa-location-arrow"></i>
      </span>
      <span>Upload GPX</span>
    </label>
    <input
      type="file"
      id="file"
      class="button"
      @change="onFileChange"
    >
  </div>
</template>
<script>
const geojson = () => {
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: []
    },
    properties: {
      'description': '',
      'name': '',
      'rating': {},
      'elevationGain': 0,
      'startPoint': null,
      'endPoint': null,
      'providerId': null,
      'providerName': null
    }
  }
}

const convertWayPointAsGeoJSONPoint = wpt => {
  let eleArray = wpt.getElementsByTagName('ele')
  let ele = '0'
  if (eleArray.length > 0) {
    ele = eleArray[0].innerHTML
  }
  return [Number(wpt.getAttribute('lon')), Number(wpt.getAttribute('lat')), Number(ele)]
}

export default {
  props: ['setGeoJSON'],
  data () {
    return {
      processing: false,
      fullwidth: true
    }
  },
  methods: {
    onFileChange (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.processFile(files[0])
    },
    processFile (file) {
      let reader = new FileReader()
      this.processing = true
      reader.readAsText(file)

      reader.onload = e => {
        // parse gpx
        const result = e.target.result
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(result, 'text/xml')
        const nameNode = xmlDoc.getElementsByTagName('name')
        let trackName = ''
        if (nameNode.length > 0) {
          trackName = nameNode[0].textContent
        }

        const tracks = Array.from(xmlDoc.getElementsByTagName('trk'))

        if (tracks.length === 0) {
          this.error = 'Can not find any track data in your gpx file'
          this.processing = false
          return
        }

        // reduce tracks as segments
        let segments = tracks.reduce(function (previous, current) {
          let trkseg = Array.from(current.getElementsByTagName('trkseg'))
          return previous.concat(trkseg)
        }, [])

        // reduce segments as points
        let points = segments.reduce(function (previous, current) {
          let trkpt = Array.from(current.getElementsByTagName('trkpt'))
          return previous.concat(trkpt)
        }, [])

        const g = geojson()
        g.geometry.coordinates = points.map(convertWayPointAsGeoJSONPoint)
        this.processing = false

        g.properties.name = trackName

        return this.setGeoJSON(g)
      }
    }
  }
}
</script>
<style scoped>
.label-file {
  cursor: pointer;
  color: #00b1ca;
  font-weight: bold;
}
.label-file:hover {
  color: #25a5c4;
}
#file {
  display: none;
}
</style>