<script>
import NeedLogin from '@/components/need-login/need-login'
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isLogged'])
  },
  methods: {
    ...mapActions(['setTargetPath']),
    wrapModalWithTargetRoute (targetRoute) {
      this.wrapModalLogin(null, null, targetRoute)
    },
    wrapModalLogin (callback, params, target) {
      if (!target) {
        target = this.$route
      }
      this.setTargetPath(target)
      if (this.isLogged) {
        if (callback) {
          callback(params)
        } else {
          this.$router.push({ name: target.name, params: target.params })
        }
      } else {
        this.$buefy.modal.open({
          component: NeedLogin,
          parent: this,
          hasModalCard: true
        })
      }
    }
  }
}
</script>