<template>
  <div>
    <p>Page {{current}} - {{total}} {{ $t('pagination.total') }}</p>
    <b-pagination
      :total="total"
      :current.sync="current"
      :order="order"
      :size="size"
      :simple="isSimple"
      :rounded="isRounded"
      :per-page="perPage"
      icon-pack="fas"
      v-on:change="pageChanged"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
    </b-pagination>
  </div>
</template>
<script>
export default {
  props: [
    'total', 'perPage', 'update'
  ],
  data () {
    return {
      current: 1,
      order: '',
      size: '',
      isSimple: false,
      isRounded: false
    }
  },
  methods: {
    pageChanged (n) {
      const offset = (n - 1) * parseInt(this.perPage, 10)
      this.update(offset)
    }
  }
}
</script>
