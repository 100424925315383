<template>
  <section>
    <p class="is-size-7 is-padded-bottom"><b>Action on selected track</b></p>

    <div>

      <action-button
        icon="fa-exchange-alt"
        :action="revert"
        :tooltip="$t('prepare.tooltip.revert')"
        :isDisabled="seletedTrackActionDisabled"
      />

      <action-button
        icon="fa-code-branch"
        :action="displayMerge"
        :tooltip="$t('prepare.tooltip.merge')"
        :isDisabled="seletedMergeActionDisabled"
      />

      <action-button
        icon="fa-crosshairs"
        :action="center"
        :tooltip="$t('prepare.tooltip.center')"
        :isDisabled="seletedTrackActionDisabled"
      />

      <action-button
        icon="fa-file-download"
        :action="download"
        :tooltip="$t('prepare.tooltip.download')"
        :isDisabled="seletedTrackActionDisabled"
      />

      <action-button
        icon="fa-chart-area"
        :action="computeElevation"
        :tooltip="$t('prepare.tooltip.elevation_srtm')"
        :isDisabled="seletedTrackActionDisabled"
      />

      <action-button
        icon="fa-trash"
        :action="deletePolyline"
        :tooltip="$t('prepare.tooltip.delete')"
        :isDisabled="seletedTrackActionDisabled"
      />

    </div>
    <section
      v-if="showMerge"
      class="padded-top"
    >

      <p class="is-size-7 is-padded-bottom">
        {{ $t('prepare.mergeCurrentTrackWith') }}
      </p>

      <b-select
        placeholder="Select a name"
        v-model="mergeTarget"
        expanded
      >
        <option
          v-for="p, idx in notSelectedPolylines"
          :value="p"
        >
        {{ p.name }} - {{p.getDistance() | toKm}} km
        </option>
      </b-select>

      <div class="field is-grouped padded-top">
        <p class="control">
          <a
            class="button is-primary"
            @click="merge"
          >{{ $t('prepare.merge') }}</a>
        </p>
        <p class="control">
          <a
            class="button is-danger"
            @click="cancelMerge"
          >{{ $t('common.cancel') }}</a>
        </p>
      </div>
    </section>
  </section>
</template>
<script>
import filters from '@/mixins/filters'
import ActionButton from './selected-track-action-button'
import togpx from 'togpx'
import FileSaver from 'file-saver'

export default {
  components: {
    ActionButton
  },
  mixins: [filters],
  data () {
    return {
      showMerge: false,
      mergeTarget: null
    }
  },
  props: ['polylines', 'add', 'remove', 'map', 'refreshMarkers', 'refreshPolylines', 'elevation', 'unselect'],
  computed: {
    notSelectedPolylines () {
      const notSelected = this.polylines.filter(p => p.selected === false)
      this.mergeTarget = notSelected[0]
      return notSelected
    },
    seletedTrackActionDisabled () {
      const selected = this.polylines.filter(p => p.selected === true).length
      return this.polylines.length === 0 || selected === 0
    },
    seletedMergeActionDisabled () {
      const selected = this.polylines.filter(p => p.selected === true).length
      return this.polylines.length < 2 || selected === 0
    }
  },
  methods: {
    revert () {
      const editablePolyline = this.getSelected()
      editablePolyline.polyline._latlngs = editablePolyline.polyline._latlngs.reverse()
      this.refreshMarkers(editablePolyline.polyline)
    },
    deletePolyline () {
      const editablePolyline = this.getSelected()
      editablePolyline.polyline._latlngs = []
      // TODO Refactor this part as we are using Leaflet object
      // passed as a prop
      this.map.removeLayer(editablePolyline.polyline)
      this.refreshMarkers()
      this.refreshPolylines()
      this.unselect()
    },
    computeElevation () {
      const p = this.getSelected()
      this.elevation(p)
    },
    download () {
      const editablePolyline = this.getSelected()
      let xml = togpx(editablePolyline.polyline.toGeoJSON(), {
        creator: 'la-trace.com',
        featureTitle: () => editablePolyline.name
      })
      let blob = new Blob([xml], { type: 'application/xml' })

      // TODO Add the nemae of the track in geojson
      FileSaver.saveAs(blob, editablePolyline.name + '.gpx')
    },
    merge () {
      const editablePolyline = this.getSelected()
      let first = editablePolyline.polyline._latlngs
      let second = this.mergeTarget.polyline._latlngs
      const coords = [...first, ...second]

      this.map.removeLayer(editablePolyline.polyline)
      this.map.removeLayer(this.mergeTarget.polyline)

      editablePolyline.polyline._latlngs = []
      this.mergeTarget.polyline._latlngs = []
      this.add(coords)
      this.refreshMarkers()
      this.refreshPolylines()
      this.showMerge = false
      this.mergeTarget = null
    },
    getSelected () {
      const selected = this.polylines.filter(p => p.selected === true)
      if (selected.length > 0) return selected[0]
    },
    displayMerge () {
      if (this.seletedMergeActionDisabled) return
      this.showMerge = true
    },
    cancelMerge () {
      this.showMerge = false
    },
    center () {
      for (let p of this.polylines) {
        if (p.selected === true) {
          // TODO Refactor this part as we are using Leaflet object
          // passed as a prop
          this.map.fitBounds(p.polyline.getBounds())
        }
      }
    }
  }
}
</script>
