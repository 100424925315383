
<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script>
const defaultLayout = 'default'
export default {
  methods: {
    setLg () {
      const lg = this.$route.params.lg
      this.$i18n.locale = lg
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  mounted () {
    this.setLg()
  },
  updated () {
    this.setLg()
  }
}
</script>
