<template>
  <div class="add-comment">
    <article
      class="media"
      v-for="(comment in comments"
    >
      <figure class="media-left">
        <p class="image is-64x64">
          <img src="https://bulma.io/images/placeholders/128x128.png">
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{comment.user.name}}</strong> <small>{{comment.creation | formatDate}}</small>
            <br>
            {{comment.content}}
          </p>
        </div>
      </div>
    </article>
    <div
      class="field is-grouped add-comment"
      v-if="isLogged"
    >
      <p class="control is-expanded">
        <input
          class="input"
          type="text"
          :placeholder="$t('trackView.addComment')"
          v-model="comment"
        >
      </p>
      <p class="control">
        <a
          class="button is-info"
          @click="addComment"
        >
          {{ $t('common.send') }}
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import filters from '@/mixins/filters'
import api from '@/mixins/api'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isLogged'])
  },
  mixins: [api, filters],
  data () {
    return {
      comment: ''
    }
  },
  methods: {
    addComment () {
      this.post('tracks/' + this.trackId + '/comments', { 'content': this.comment }).then(response => {
        this.comment = ''
        this.$buefy.toast.open({
          message: this.$t('common.operation.success'),
          type: 'is-info'
        })
        this.$parent.$emit('commentAdded')
      })
    }
  },
  props: ['comments', 'trackId']
}
</script>
<style scoped>
.add-comment {
  margin-top: 20px;
}
</style>
