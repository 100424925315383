<template>
  <div class="locale-changer">
    <a v-for="(lang, i) in langs" @click="changeLg(lang.locale)">
      <img :src="lang.flag()" :alt="lang.locale" width="32"  class="lg-link"> 
    </a> 
  </div>
</template>

<script>
// const DEFAULT_LG = 'fr'
export default {
  mounted () {
    // let lg = window.localStorage.getItem('locale')

    // if (this.$i18n.locale === lg) {
    //   return
    // }

    // if (lg && this.checkLocale(lg) && this.$i18n.locale !== lg) {
    //   this.changeLg(lg)
    //   return
    // }
    // const locale = navigator.language || navigator.userLanguage

    // if (locale) {
    //   const segments = locale.split('-')
    //   lg = segments[0]
    // } else {
    //   lg = DEFAULT_LG
    // }

    // if (this.checkLocale(lg)) {
    //   this.changeLg(lg)
    // }
  },
  methods: {
    checkLocale (lg) {
      return ['fr', 'en'].indexOf(lg) !== -1
    },
    changeLg (lg) {
      // this.$i18n.locale = lg
      window.localStorage.setItem('locale', lg)
      this.$router.push(`/${lg}`)
      this.$buefy.toast.open({
        message: this.$t('home.localeChanged'),
        type: 'is-success'
      })
    }
  },
  data () {
    return {
      langs: [
        {
          locale: 'en',
          flag: () => {
            return require('../../assets/flag-united-states.svg')
          }
        },
        {
          locale: 'fr',
          flag: () => {
            return require('../../assets/flag-france.svg')
          }
        }
      ]
    }
  }
}
</script>
<style>
  .lg-link{
    margin-right:10px;
  }
</style>
