<template>
  <div id="elevation-wrapper">
    <apexchart
      width="100%"
      height="300"
      type="area"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import bus from '../bus-event'
import api from '@/mixins/api'
let formatElevation = function (row) {
  let e = {
    x: row[0],
    y: row[1],
    lng: row[2],
    lat: row[3]
  }
  return e
}

export default {
  props: ['trackId'],
  mixins: [api],
  data () {
    return {
      chartOptions: {
        tooltip: {
          marker: {
            show: true
          },
          followCursor: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const p = w.config.series[seriesIndex].data[dataPointIndex]
            bus.$emit('map.movemarker', p.lng, p.lat)
            return '<div><span>' + p.y + ' m </span></div>'
          }
        },
        fill: {
          type: 'gradient',
          colors: '#AFD425',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          id: 'elevation'
        },
        stroke: {
          colors: '#CCC',
          curve: 'smooth',
          show: true,
          width: 2
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'numeric',
          labels: {
            formatter: function (value) {
              return (value / 1000).toFixed(2)
            }
          }
        },
        yaxis: {
          labels: {
            show: true
          }
        }
      },
      series: []
    }
  },
  created () {
    let me = this
    let url = '/tracks/' + this.trackId + '/elevations'
    me.get(url).then(response => {
      this.series = [
        {
          name: 'elevation',
          data: response.data.map(formatElevation)
        }
      ]
    })
  }
}
</script>
<style scoped>
#elevation-wrapper {
  width: 100%;
  height: 300px;
}
</style>