<template>
  <nav class="level pad">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ $t('trackView.distance') }}</p>
        <p class="title">
          {{track.distance | toKm}} km
        </p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ $t('trackView.elevation') }}</p>
        <p class="title">{{track.elevationGain}} m</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ $t('trackView.downloads') }}</p>
        <p class="title">{{track.downloads}}</p>
      </div>
    </div>
  </nav>
</template>
<script>
import filters from '@/mixins/filters'

export default {
  mixins: [filters],
  props: ['track']
}
</script>
