<template>
  <div>
    <h3
      class="title"
      :class="{'is-hidden': hideTitle}"
    >
      <span class="icon is-medium">
        <i class="fas fa-search"></i>
      </span>
      {{ headtitle }}
    </h3>
    <div class="columns" v-for="tracks in chunkedTracks">
        <div class="column is-one-quarter" v-for="track in tracks">
          <div
            class="card padding cursor"
            @click="goToSelectedTrack(track.id)"
          >
            <div class="card-image">
              <figure class="image">
                <lazy-image :src="'https://la-trace.com/api/render/' + track.id" />
              </figure>
            </div>
            <header class="card-header">
              <p class="card-header-title" v-html="smartEllipsis(track.name, '', 30)"></p>
            </header>
            <div class="card-content">
              <div
                class="content"
                v-html="smartEllipsis(track.description, keyword, 80)"
              ></div>
              <span>
                <i class="fas fa-map-marker"></i>
                &nbsp;<span
                  v-html="smartEllipsis(track.startAddress.city, keyword)"
                  class="tSmall"
                ></span>
              </span>
            </div>
            <footer class="card-footer">
              <span class="card-footer-item tSmall">
                <i class="fas fa-tachometer-alt"></i>
                &nbsp;<span class="tSmall">{{ track.distance | toKm }} km</span>
              </span>
              <span class="card-footer-item  is-small">
                <i class="fas fa-chart-line"></i>
                &nbsp;<span class="tSmall">{{ track.elevationGain }} m</span>
              </span>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <!-- <li v-for="track in tracks">
        <router-link :to="{name: 'track.detail', params: {id: track.id}}">
          {{ track.name }}
        </router-link>

        {{ track.elevationGain }} m</td>
        {{ track.distance | toKm }} km</td>
      </li> -->
    <!-- <tr v-if="tracks.length === 0">
          <td colspan="4">{{ $t('search.noResults') }}</td>
        </tr> -->
  </div>
</template>
<script>
import filters from '@/mixins/filters'
import LazyImage from '@/components/lazy-image/main.vue'
import chunk from '@/services/chunk'

export default {
  mixins: [filters],
  filters: {
    smartEllipsis (haystack, needle, size) {
      const pattern = new RegExp(needle, 'i')
      return haystack.replace(pattern, `<u>${needle}</u>`)
      // if (!size) {
      //   size = 300
      // }
      // if (haystack.length < size) return haystack
      // return haystack.substring(0, size) + '...'
      // return haystack
    }
  },
  methods: {
    smartEllipsis (haystack, needle, size) {
      const pattern = new RegExp(needle, 'i')
      haystack = haystack.replace(pattern, `<u>${needle}</u>`)
      if (!size) {
        size = 100
      }
      if (haystack.length < size) return haystack
      return haystack.substring(0, size) + '...'
      // return haystack
    },
    goToSelectedTrack (id) {
      this.$router.push({ name: 'track.detail', params: { id: id } })
    }
  },
  components: {
    LazyImage
  },
  computed: {
    chunkedTracks () {
      return chunk(this.tracks, 4)
    },
    headtitle () {
      if (this.title === '') {
        return this.$t('search.results')
      } else {
        return this.title
      }
    }
  },
  props: {
    tracks: Array,
    title: {
      type: String,
      default: ''
    },
    keyword: {
      type: String,
      default: ''
    },
    displayDownloads: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.padding {
  margin-top: 15px;
}
.cursor {
  cursor: pointer;
}
.tSmall {
  font-size: 0.9em;
}
.card-content {
  height: 150px;
}
</style>
