<template>
  <aside class="column is-one-quarter is-fullheight">
    <section class="section">
    <h1 class="title is-5">{{track.name}}</h1>
    <p class="content is-small">
      {{ $t('trackView.trackUploaded') }} 
      <router-link
        :to="{name: 'user.profile', params: {userId: track.user.id}}"
      > <strong>{{track.user.name}}</strong> 
      </router-link>
      {{ $t('trackView.on') }} {{track.creation | formatDate}}
    </p>
    <hr>
    <p v-html="track.description"></p>
    <hr>
    <div v-if="isOwner">
      <router-link
        :to="{name: 'track.edit', params: {id: track.id}}"
        class="button is-info is-fullwidth"
      >{{ $t('trackView.edit') }}</router-link>
      <a
        class="button is-danger is-fullwidth margin"
        @click="deleteTrack(track.id)"
      >{{ $t('trackView.delete') }}</a>
      <hr>
    </div>
    <p><strong>{{ $t('trackView.start') }}</strong> : {{track.startAddress.city}}</p>
    <p><strong>{{ $t('trackView.end') }}</strong> : {{track.endAddress.city}}</p>
    <hr class="is-hidden-mobile">
    <div
      v-for="(value, key) in track.rating"
      class="is-hidden-mobile"
    >
      <div class="columns">
        <div class="column">{{key}}</div>
        <div
          class="column"
          v-if="isNaN(value)"
        >{{value}}</div>
        <div
          class="column"
          v-else
        >
          <star-rating
            :rating="parseInt(value, 10)"
            :star-size="20"
            read-only
            :show-rating="false"
          ></star-rating>
        </div>
      </div>
    </div>
    <hr>
    <router-link
      :to="{name: 'search.map', query: getSearchQueryString}"
      :title="$t('trackView.searchTitle', {location: track.startAddress.city})"
      class="button is-info is-fullwidth is-hidden-mobile"
    >{{ $t('trackView.search')}}</router-link>
    <br>
    <a
      @click="check(track.id)"
      class="button is-info is-fullwidth"
    >{{ $t('trackView.download') }}</a>
    <br>
    <a
      @click="checkEdit(track.id)"
      class="button is-info is-fullwidth is-hidden-mobile"
    >{{ $t('trackView.edit') }}</a>
    <br>
    <!--img :src="getImageLink"-->
    </section>
  </aside>
</template>
<script>
import FileSaver from 'file-saver'
import api from '@/mixins/api'
import filters from '@/mixins/filters'
import tools from '@/mixins/tools'
import StarRating from 'vue-star-rating'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    StarRating
  },
  mixins: [api, filters, tools],
  props: ['track'],
  computed: {
    ...mapGetters(['isLogged']),
    isOwner () {
      const id = this.getUserId()
      return id === this.track.user.id
    },
    getSearchQueryString () {
      return {
        q: `lat=${this.track.startAddress.latitude},lng=${this.track.startAddress.longitude},z=13`
      }
    }
  },
  methods: {
    ...mapGetters(['getUserId']),
    ...mapActions(['setEdit']),
    deleteTrack (id) {
      this.delete('tracks/' + id).then(response => {
        this.$router.push({ path: '/' })
      })
    },
    checkEdit (id) {
      this.wrapModalLogin(this.edit, id)
    },
    edit (id) {
      this.get('tracks/' + id + '?factor=0.0003', { headers: { Accept: 'application/vnd.geo+json' } }).then((response) => {
        this.setEdit(response.data).then(() => {
          this.$router.push({ name: 'prepare' })
        })
      }).catch(e => {
        console.error('Error while trying to download : %d', id)
      })
    },
    check (id) {
      if (!this.isLogged) {
        if (window._paq) {
          window._paq.push(['trackEvent', 'Download.Without.Login'])
        }
      }
      this.wrapModalLogin(this.download, id)
    },
    download (id) {
      this.get('tracks/' + id + '/gpx', { 'headers': { 'Accept': 'application/gpx+xml' } }).then((response) => {
        let blob = new Blob([response.data], { type: 'application/xml' })
        FileSaver.saveAs(blob, this.track.name + '.gpx')
        window._paq.push(['trackEvent', 'Download', 'Track', id, this.track.name])
      })
    }
  }
}
</script>
<style>
.margin {
  margin-top: 10px;
}
</style>
