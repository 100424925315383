import App from './App.vue'

import 'element-ui/lib/theme-default/index.css'
import 'animate.css'
import './css/main.css'
import './css/nprogress.css'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Buefy from 'buefy'
import VueI18n from 'vue-i18n'
import VueApexCharts from 'vue-apexcharts'
// import VueMatomo from 'vue-matomo'

import search from '@/components/search/map-search'
import KwSearch from '@/components/search-keyword/search'
import home from '@/components/home/home'
import trackDetail from '@/components/track-detail/track-detail'
import TrackEdit from '@/components/track-edit/track-edit'
import Register from '@/components/register/register'
import traxeditor from '@/components/track-prepare'
import upload from '@/components/upload/upload'
import login from '@/components/login/login-modal'
import Profile from '@/components/profile/show'
import PublicProfile from '@/components/profile/public'
import Strava from '@/components/connector/strava/strava'
import MagicLink from '@/components/magic-link/magic-link'
import EmailValidation from '@/components/email-validation/email-validation'
import MyTracks from '@/components/my-tracks/my-tracks'
// import Test from '@/components/two-column'
import { store } from './store/store'
import './scss/main.scss'
import './scss/fa-5.8.2/scss/fontawesome.scss'
import './scss/fa-5.8.2/js/all.js'

// import messages from './messages/messages'

import Default from './layouts/with-menu'
import NoMenu from './layouts/no-menu'

Vue.component('default-layout', Default)
Vue.component('no-menu-layout', NoMenu)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueRouter)
Vue.use(Buefy)
Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(VueApexCharts)

const messages = {
  en: {
    profile: {
      user: 'User',
      name: 'Name',
      memberSince: 'InsMember since',
      activityHeatMap: 'Activities Heatmap',
      tracks: 'GPS Tracks'
    },
    common: {
      'operation': {
        'success': 'Operation successful',
        'fail': 'Operation failed',
        'error': 'An error occured'
      },
      'cancel': 'Cancel',
      'send': 'Send',
      'needLogin': 'You need to be logged-in to access to this functionality.'
    },
    seo: {
      home: {
        title: 'Homepage'
      },
      search: {
        title: 'Search on a map'
      },
      searchkw: {
        title: 'Search by keyword'
      },
      login: {
        title: 'Login'
      },
      register: {
        title: 'Create your account'
      },
      strava: {
        title: 'Connect your Strava account'
      }
    },
    prepare: {
      name: 'Name',
      distance: 'Distance',
      noContent: 'No track yet',
      merge: 'Merge selected tracks',
      download: 'Downaload selected track',
      displayHeatmap: 'Display HeatMap',
      hideHelp: 'Display help',
      showHelp: 'Hide help',
      rangeExceeded: 'Map is too wide to display the heatmap, please zoom in.',
      strmRangeExceeded: 'The SRTM1GL elevation is not available here',
      locationFound: 'Current location found',
      localisationError: 'An error occured while retriving your location',
      localisationSearchInProgress: 'Trying to find your location ',
      mergeCurrentTrackWith: 'Merge selected track with:',
      help: {
        draw: 'Allows to draw a new track on the map. To end the current track, click on the last vertex.',
        delete_vertex: 'Allows to remove vertex on track by clinkg on it',
        add_vertex: 'Allows to continue a track. To end the current track, click on the last vertex.',
        split: 'Allows to split a track in two segements',
        standby: 'Default mode, no action enabled',
        heatmap: 'Heatmap displays the points available for the current bounding box, from blue to red depending on the attendance. This feature is only available on high zoom level.'
      },
      tooltip: {
        merge: 'Merge current track with another track',
        center: 'Center the map on the current track',
        download: 'Download the current track as a gpx file',
        elevation_srtm: 'Get SRTM1GL elevations path',
        delete: 'Delete the current track',
        revert: 'Invert the current track',
        findCurrentPosition: 'Center track on your current position',
        draw: 'Draw',
        deleteVertex: 'Delete vertex',
        addVertex: 'Add vertex',
        split: 'Split track',
        select: 'Select'
      },
      displayGR: 'Displays the GR tracks'
    },
    magiclink: {
      codeHasExpired: 'This code has expired',
      codeDoesntExist: 'This code does not exist or has already been used'
    },
    emailValidation: {
      success: 'Your email is validated, you can now log-in!'
    },
    home: {
      baseline: 'Find your next track',
      searchByKeyword: 'Search by keyword',
      search: 'Search',
      findOnAMap: 'Find a track on a Map',
      signIn: 'Sign in',
      signUp: 'S\'inscrire',
      login: 'Log-in to your account',
      create: 'Create',
      createAccount: 'Create an account',
      publish: 'Publish',
      publishTrack: 'Publish your gpx file',
      localeChanged: 'Lannguage changed !'
    },
    menu: {
      search: 'Map Search',
      blog: 'Blog',
      upload: 'Upload',
      login: 'Login',
      register: 'Register',
      logout: 'Logout',
      keywordSearch: 'Enter a keyword',
      myTracks: 'My tracks',
      myProfile: 'My profile'
    },
    footer: {
      home: 'Home',
      login: 'Login',
      twitter: 'Twitter',
      email: 'Email',
      facebook: 'Facebook',
      gpx: 'GPX File format',
      gpsBabel: 'GPSBabel an essential tool',
      sendMessage: 'Send message',
      yourMessage: 'Your message'
    },
    login: {
      codeDoesntExist: 'This code has already been used or it does not exist!',
      codeHasExpired: 'This code has expired',
      explain: 'A magic link is sent to you by email, so you can login without your password. If you prefer to login with your email address and password, click',
      here: 'here',
      email: 'Email',
      login: 'Login',
      yourEmail: 'Your Email',
      password: 'Password',
      yourPassword: 'Your password',
      noAccountYet: 'Don\'t have an account yet?',
      signUp: 'Sign Up',
      sendMagicLink: 'Send Magic Link',
      success: 'If this email exists, we have just sent you a link!',
      ifYouPreferMagicLink: 'If you prefer to login with a Magic Link, click',
      signIn: 'Sign in',
      failed: 'Can\'t login with this email and password',
      accountInactive: 'Your account is not yet activated'
    },
    trackView: {
      distance: 'distance',
      elevation: 'elevation',
      downloads: 'downloads',
      edit: 'Edit',
      delete: 'Delete',
      search: 'Search Around',
      download: 'Download',
      start: 'Start',
      end: 'End',
      trackUploaded: 'Track uploaded by',
      on: 'on',
      addComment: 'Write you own comment on the track',
      searchTitle: 'Find GPS file near {location}'
    },
    trackEdit: {
      description: 'description',
      elevation: 'elevation',
      pictures: 'pictures',
      video: 'video',
      rating: 'rating',
      pois: 'pois',
      return: 'Return to the track page',
      name: 'Name',
      save: 'Save',
      chooseFile: 'Choose a file',
      caption: 'Caption',
      size: 'Size',
      gpsData: 'GPS Data',
      cancel: 'Cancel',
      chooseIcon: 'Choose Icon',
      longitude: 'longitude',
      latitude: 'latitude',
      icon: 'icon',
      actions: 'actions',
      new: 'new',
      copyVideoLink: 'Copy the URL of your video',
      deleteVideo: 'Delete video'
    },
    search: {
      distanceMin: 'Min Distance',
      distanceMax: 'Max Distance',
      elevationMax: 'Max Elevation',
      elevationGain: 'Elevation Gain',
      name: 'Name',
      distance: 'Distance',
      results: 'Search Results',
      details: 'Details',
      cancel: 'Cancel',
      filter: 'Filter',
      city: 'Enter name of a city',
      displayStartPoint: 'Display start points'
    },
    pagination: {
      total: 'Records found'
    },
    register: {
      success: 'Thank you! Please check your email to validate your account',
      email: 'Email',
      accountCreated: 'Account created',
      login: 'Login',
      password: 'Password',
      name: 'Name',
      emailNotValid: 'Email is not valid',
      passwordSize: 'Password should be at least 8 characters',
      passwordAgain: 'Type password again',
      passwordVerification: 'Password verification',
      passwordDoesntMatch: 'Password doesn\'t match',
      username: 'Username',
      signup: 'Sign Up',
      accountAlreadyExists: 'Username or email already exists',
      accountCreation: 'Account creation'
    }
  },
  fr: {
    profile: {
      user: 'Utilisateur',
      name: 'Nom',
      memberSince: 'Inscrit le',
      activityHeatMap: 'Carte des publications',
      tracks: 'Trace GPS'
    },
    common: {
      'operation': {
        'success': 'Action effectuée',
        'fail': 'Erreur pendant l\'action'
      },
      'cancel': 'Annuler',
      'send': 'Envoyer',
      'needLogin': 'Il faut être identifié pour accèder à cette fonctionnalité.'
    },
    seo: {
      home: {
        title: 'Accueil'
      },
      search: {
        title: 'Chercher une trace GPX sur une carte'
      },
      searchkw: {
        title: 'Chercher une trace GPX par mot clé'
      },
      login: {
        title: 'Se connecter'
      },
      regsiter: {
        title: 'Créer un compte'
      },
      strava: {
        title: 'Connecter son compte strava'
      }
    },
    magiclink: {
      codeDoesntExist: 'Ce code a déjà été utilisé ou n\'existe pas!',
      codeHasExpired: 'Ce code a expiré'
    },
    emailValidation: {
      success: 'Votre email est validé, vous pouvez désormais vous identifier !'
    },
    home: {
      baseline: 'Trouvez votre prochaine trace',
      searchByKeyword: 'Recherche par mot clé',
      search: 'Chercher des parcours',
      findOnAMap: 'Par position géographique trouvez de nouveaux parcours',
      signIn: 'Log in',
      signUp: 'S\'inscrire',
      login: 'Accès à votre compte',
      create: 'Créer vos parcours',
      createAccount: 'Créer un compte',
      publish: 'Publier',
      publishTrack: 'Publier votre itinéraire',
      localeChanged: 'Langue changée !',
      createTrack: 'Dessinez vos traces sur une carte et téléchargez le fichier GPX'
    },
    menu: {
      search: 'Recherche',
      blog: 'Blog',
      upload: 'Charger une trace GPX',
      login: 'Login',
      register: 'Créer un compte',
      logout: 'Logout',
      keywordSearch: 'Entrez un mot clé',
      myTracks: 'Mes traces',
      myProfile: 'Mon profil'
    },
    footer: {
      home: 'Accueil',
      login: 'Login',
      twitter: 'Twitter',
      email: 'Email',
      facebook: 'Facebook',
      gpx: 'GPX File format',
      gpsBabel: 'GPSBabel un outil essentiel',
      sendMessage: 'Envoyer',
      yourMessage: 'Votre message'
    },
    trackView: {
      distance: 'distance',
      elevation: 'denivelé',
      downloads: 'téléchargements',
      edit: 'Modifier',
      delete: 'Supprimer',
      search: 'Rechercher à proximité',
      download: 'Télécharger la trace GPS',
      start: 'Départ',
      end: 'Arrivé',
      trackUploaded: 'Trace uploadée par',
      on: 'le',
      addComment: 'Ajoutez votre commentaire sur la trace',
      searchTitle: 'Trouvez des traces GPX à proximité de {location}'
    },
    trackEdit: {
      description: 'description',
      elevation: 'dénivelé',
      pictures: 'photos',
      video: 'video',
      rating: 'appréciations',
      pois: 'pois',
      return: 'Retour',
      name: 'Nom',
      save: 'Sauver',
      chooseFile: 'Fichier',
      caption: 'Légende',
      size: 'Taille',
      gpsData: 'Donnée GPS',
      cancel: 'Annuler',
      chooseIcon: 'Choix Icône',
      longitude: 'longitude',
      latitude: 'latitude',
      icon: 'icône',
      actions: 'actions',
      new: 'new',
      copyVideoLink: 'Copier l\'url de la vidéo',
      deleteVideo: 'Supprimer'
    },
    search: {
      distanceMin: 'Distance Min',
      distanceMax: 'Distance Max',
      elevationMax: 'Dénivelé Max',
      elevationGain: 'Dénivelé ',
      name: 'Nom',
      distance: 'Distance',
      results: 'Résultats de recherche',
      details: 'Détails',
      cancel: 'Annuler',
      filter: 'Filtrer',
      city: 'Ville, Village, etc...',
      displayStartPoint: 'Afficher les points de départ'
    },
    login: {
      explain: 'Un lien de connexion vous est envoyé par mail, afin de vous connecter sans mot de passe. Si vous préférez utiliser votre email et votre mot de passe cliquez-',
      here: 'ici',
      email: 'Email',
      login: 'Email',
      yourEmail: 'Votre Email',
      password: 'Mot de passe',
      yourPassword: 'Votre mot de passe',
      noAccountYet: 'Vous n\'avez pas encore de compte?',
      signUp: 'Inscrivez-vous',
      sendMagicLink: 'Envoyer un lien',
      success: 'Si cet email correspond à un compte, nous venons de vous envoyer un lien!',
      ifYouPreferMagicLink: 'Si vous préférez vous connecter avec un lien envoyé par email, cliquez-',
      signIn: 'Se connecter',
      failed: 'Impossible de vous connecter avec cet email et ce mot de passe',
      accountInactive: 'Votre compte n\'est pas encore activé'
    },
    pagination: {
      total: 'Résultat(s) au total'
    },
    register: {
      success: 'Merci ! Un email vous a été envoyé pour valider votre compte',
      email: 'Email',
      accountCreated: 'Compte créé',
      login: 'Login',
      password: 'Password',
      name: 'Nom',
      emailNotValid: 'L\'Email n\'est pas valide',
      passwordSize: 'Le mot de passe doit contenir au moins 8 charactères',
      passwordAgain: 'Tapper le mot de passe à nouveau',
      passwordVerification: 'Vérification du mot de passe',
      passwordDoesntMatch: 'Les mots de passe ne correspondent pas',
      username: 'Nom d\'utilisateur',
      signup: 'Créer un compte',
      accountAlreadyExists: 'Ce compte existe déjà !',
      accountCreation: 'Création de compte'
    },
    prepare: {
      name: 'Nom',
      distance: 'Dist.',
      noContent: 'No track yet',
      merge: 'Fusionner',
      download: 'Télécharger la sélection',
      displayHeatmap: 'Afficher la carte de densité (heatmap)',
      strmRangeExceeded: 'L\'altitude SRTM1GL n\'est pas disponible',
      hideHelp: 'Afficher l\'aide contextuelle',
      showHelp: 'Masquer l\'aide contextuelle',
      rangeExceeded: 'La zone de la carte est trop vaste pour afficher la carte de densité, merci de zoomer.',
      locationFound: 'Position détectée',
      localisationError: 'Une erreur est survenue pendant la recherche de votre position',
      localisationSearchInProgress: 'Recherche de votre position en cours',
      mergeCurrentTrackWith: 'Fusionner la trace séléctionnée avec:',
      help: {
        draw: 'Permet de dessiner une nouvelle trace. Pour finaliser celle ci, cliquez sur le dernier point',
        delete_vertex: 'Permet de supprimer un point',
        add_vertex: 'Permet de continuer une trace en cliquant sur le premier ou le dernier point',
        split: 'Permet de couper une trace en deux segments',
        standby: 'Mode par défaut',
        heatmap: 'La carte de densité affiche les points disponibles en base données correspondant à la zone affichée par la carte. Les zones apparaitront du bleu au rouge en fonction de la fréquentation. Cette fonctionnalité n\'est disponible qu\'à des niveaux de zoom élevés.'
      },
      tooltip: {
        merge: 'Fusionner la trace sélectionnée avec une autre trace',
        center: 'Centrer la carte sur la trace sélectionnée',
        download: 'Télécharge la trace sélectionnée au format GPX',
        delete: 'Supprime la trace sélectionnée',
        revert: 'Inverser le sens de la trace sélectionnée',
        findCurrentPosition: 'Centrer la carte sur la position actuelle',
        draw: 'Dessiner une trace',
        elevation_srtm: 'Courbe d\'altitudes',
        deleteVertex: 'Supprimer un vertex',
        addVertex: 'Ajouter un vertex',
        split: 'Couper une trace en 2 segments',
        select: 'Sélection'
      },
      displayGR: 'Afficher les GR (Grande randonnée)'
    }
  }
}

const i18n = new VueI18n({
  locale: 'fr',
  messages
})

const routes = [
  {
    path: '/',
    redirect: `/fr`
  },
  {
    path: '/:lg',
    component: {
      template: '<router-view />'
    },
    children: [
      {
        path: '',
        name: 'home',
        component: home,
        meta: {
          layout: 'no-menu',
          title: 'seo.home.title'
        }
      },
      {
        name: 'search.map',
        path: 'search/:data?',
        component: search,
        meta: {
          title: 'seo.search.title'
        }
      },
      {
        name: 'search.keyword',
        path: 'search-by-keyword',
        component: KwSearch,
        meta: {
          title: 'seo.searchkw.title'
        }
      },
      {
        name: 'login',
        path: 'login',
        component: login,
        meta: {
          title: 'seo.login.title'
        }
      },
      {
        name: 'track.detail',
        path: 'track/:id',
        component: trackDetail
      },
      {
        name: 'register',
        path: 'register',
        component: Register,
        meta: {
          title: 'seo.register.title'
        }
      },
      {
        name: 'connector.strava',
        path: 'connector/strava',
        component: Strava,
        meta: {
          title: 'seo.register.title'
        }
      },
      {
        name: 'prepare',
        path: 'prepare',
        component: traxeditor
      },
      {
        name: 'magic-link',
        path: 'magic-link/:code',
        component: MagicLink
      },
      {
        name: 'verify-email',
        path: 'verify-email/:code',
        component: EmailValidation
      },
      {
        name: 'my.tracks',
        path: 'my-tracks',
        component: MyTracks
      },
      {
        name: 'track.edit',
        path: 'track/edit/:id',
        component: TrackEdit
      },
      {
        name: 'upload',
        path: 'upload',
        component: upload
      },
      {
        name: 'my.profile',
        path: 'profile',
        component: Profile
      },
      {
        name: 'user.profile',
        path: 'user/profile/:userId',
        component: PublicProfile
      }
    ]
  }]

const router = new VueRouter({
  mode: 'history',
  routes
})

// if (process.env.NODE_ENV !== 'development') {
//   Vue.use(VueMatomo, {
//     // Configure your matomo server and site by providing
//     host: 'https://matomo.la-trace.com',
//     siteId: 1,

//     // Changes the default .js and .php endpoint's filename
//     // Default: 'matomo'
//     trackerFileName: 'matomo',

//     // Overrides the autogenerated tracker endpoint entirely
//     // Default: undefined
//     // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

//     // Overrides the autogenerated tracker script path entirely
//     // Default: undefined
//     // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

//     // Enables automatically registering pageviews on the router
//     router: router,

//     // Enables link tracking on regular links. Note that this won't
//     // work for routing links (ie. internal Vue router links)
//     // Default: true
//     enableLinkTracking: true,

//     // Require consent before sending tracking information to matomo
//     // Default: false
//     requireConsent: false,

//     // Whether to track the initial page view
//     // Default: true
//     trackInitialView: true,

//     // Run Matomo without cookies
//     // Default: false
//     disableCookies: false,

//     // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
//     // Default: false
//     enableHeartBeatTimer: false,

//     // Set the heartbeat timer interval
//     // Default: 15
//     heartBeatTimerInterval: 15,

//     // Whether or not to log debug information
//     // Default: false
//     debug: false,

//     // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
//     // Default: undefined
//     userId: undefined,

//     // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
//     // Default: undefined, example '*.example.com'
//     cookieDomain: undefined,

//     // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
//     // Default: undefined, example: '*.example.com'
//     domains: undefined,

//     // A list of pre-initialization actions that run before matomo is loaded
//     // Default: []
//     // Example: [
//     //   ['API_method_name', parameter_list],
//     //   ['setCustomVariable','1','VisitorType','Member'],
//     //   ['appendToTrackingUrl', 'new_visit=1'],
//     //   etc.
//     // ]
//     preInitActions: []
//   })
// }

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  const language = to.params.lg
  if (!['en', 'fr'].includes(language)) {
    router.push('/fr')
  }
  next()
})

new Vue({
  router,
  i18n,
  props: ['hidemenu'],
  render: h => h(App),
  store: new Vuex.Store(store)
}
).$mount('#app')

