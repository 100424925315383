<script>
export default {
  mounted () {
    const title = this.$route.meta.title
    if (title) {
      this.setTitle(this.$t(title))
    } else {
      this.setDefaultTitle()
    }
  },
  methods: {
    setDefaultTitle () {
      this.setTitle('Welcome')
    },
    setTitle (content) {
      document.title = `${content} | la-trace.com`
    },
    setDescription (content) {
      if (document.querySelector('meta[name="description"]')) {
        document.querySelector('meta[name="description"]').setAttribute('content', content)
      }
    }
  }
}
</script>