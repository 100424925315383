<template>
  <div class="container">
    <div class="steps">
      <ul class="steps-container">
          <li style="width:25%;" v-bind:class="{activated: step > 0}">
              <div class="step">
                  <div class="step-image"><span></span></div>
                  <div class="step-current">Step 1</div>
                  <div class="step-description">Upload File</div>
              </div>
          </li>
          <li style="width:25%;" v-bind:class="{activated: step > 1}">
              <div class="step">
                  <div class="step-image"><span></span></div>
                  <div class="step-current">Step 2</div>
                  <div class="step-description">Add Description</div>
              </div>
          </li>
          <li style="width:25%;" v-bind:class="{activated: step > 2}">
              <div class="step">
                  <div class="step-image"><span></span></div>
                  <div class="step-current">Step 3</div>
                  <div class="step-description">Rating</div>
              </div>
          </li>
          <li style="width:25%;" v-bind:class="{activated: step > 3}">
              <div class="step">
                  <div class="step-image"><span></span></div>
                  <div class="step-current">Step 4</div>
                  <div class="step-description">Summary and publish</div>
            </div>
          </li>
      </ul>
      <div class="step-bar" v-bind:style="{width: 25*step + '%'}"></div>
    </div>

    <div id="wrapper" v-bind:class="{hidden: !analyzed}">
      <div id="map"></div>
    </div>

    <div v-if="step === 0">
      <div class="columns">
        <div class="column">
          <div class="notification is-danger" v-if="error">{{error}}</div>
        </div>
      </div>
      <div class="columns is-centered" >
        <div class="column is-one-quarter">
          <label for="file" class="label-file button is-primary is-large" v-bind:class="{'is-loading': processing}" id="upload-button">
            <span class="icon is-medium">
              <i class="fas fa-location-arrow"></i>
            </span>
            <span>Choose a GPX File</span>
          </label>
          <input type="file" id="file" class="button" @change="onFileChange">
        </div>
      </div>
    </div>

    <div id="main-upload" class="has-text-centered step-1" v-if="step === 1">
      <div class="notification is-success" v-if="analyzed">🎉 File processing is successful</div>
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <td>File <br>
              <span class="is-size-7">The filename</span>
            </td>
            <td>{{track.info.fileName}}</td>
          <tr>
          <tr>
            <td>Number of Points <br>
              <span class="is-size-7">The number of points</span>
            </td>
            <td>{{track.info.nbOfPoints}}</td>
          <tr>
          <tr>
            <td>Number of Points Reduced <br>
              <span class="is-size-7">The shape is simplified by the
              <a href="https://en.wikipedia.org/wiki/Ramer%E2%80%93Douglas%E2%80%93Peucker_algorithm" target="_blank">Ramer–Douglas–Peucker</a> algorithm</span>
            </td>
            <td>{{track.info.reducedNbOfPoints}}</td>
          </tr>
          <tr>
            <td>Distance <br>
              <span class="is-size-7">The distance is calculated with the
              <a href="https://en.wikipedia.org/wiki/Haversine_formula" target="_blank">Haversine</a> formula</span>

            </td>
            <td>{{track.info.distance | toKm}} km</td>
          </tr>
          <tr>
            <td>Elevation gain <br>
               <span class="is-size-7">The elevation gain is calculated wth a
               <a href="https://en.wikipedia.org/wiki/Moving_average" target="blank">SMA</a> (simple moving average)</a></span>
            </td>
            <td>{{track.geojson.properties.elevationGain}} m</td>
          </tr>
          <tr>
            <td>Start Point<br>
               <span class="is-size-7">Reverse geocoding with
               <a href="https://www.openstreetmap.org/" target="blank">OSM</a></span>
            </td>
            <td><i class="fas fa-circle-o-notch fa-spin fa-3x fa-fw" v-if="startPoint === ''"></i> {{startPoint}}</td>
          </tr>
           <tr>
            <td>End Point<br>
                <span class="is-size-7">Reverse geocoding with
                <a href="https://www.openstreetmap.org/" target="blank">OSM</a></span>
            </td>
            <td><i class="fas fa-circle-o-notch fa-spin fa-3x fa-fw"  v-if="endPoint === ''"></i> {{endPoint}}</td>
          </tr>
        </tbody>
      </table>
      <p></p>
    </div>

    <div class="step-2" v-if="step === 2" style="margin-bottom:1.5rem">
      <titled></titled>
    </div>

    <div class="step-3" v-if="step === 3">
      <rating></rating>
    </div>

    <div class="step-4" v-if="step === 4">
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{{track.geojson.properties.name}}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{{track.geojson.properties.description}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="columns is-centered" v-if="step > 0">
        <div class="column is-one-quarter">
          <a class="button is-info is-fullwidth" @click="next()" v-if="step < 4" :disabled="notValidStep">Continue</a>
          <a class="button is-info is-fullwidth" @click="save()" v-else="">Save</a>
        </div>
        <div class="column is-one-quarter">
          <a class="button is-danger is-fullwidth" @click="reset()">Cancel</a>
        </div>
    </div>
  </div>

</template>

<script>
import simplify from 'simplify-geojson/index.js'
import haversine from '../../services/haversine'
import filters from '@/mixins/filters'
import map from '@/mixins/map'
import titled from './title-and-desc'
import rating from './rating'
import api from '@/mixins/api'
import ReverseGeocoding from '@/mixins/reverse-geocoding'
// import {getElevationGain} from 'geojson-elevation-gain'

function getElevationGain () {}

const convertWayPointAsGeoJSONPoint = wpt => {
  let eleArray = wpt.getElementsByTagName('ele')
  let ele = '0'
  if (eleArray.length > 0) {
    ele = eleArray[0].innerHTML
  }
  return [Number(wpt.getAttribute('lon')), Number(wpt.getAttribute('lat')), Number(ele)]
}

export default {
  mixins: [filters, api, map, ReverseGeocoding],
  computed: {
    name () {
      return this.$store.state.track.geojson.properties.name
    },
    description () {
      return this.$store.state.track.geojson.properties.description
    },
    step () {
      return this.$store.getters.step
    },
    track () {
      return this.$store.getters.uploadTrack
    },
    cantContinue () {
      return this.$store.getters.cantContinue
    },
    endPoint () {
      return this.setDisplayName(this.track.geojson.properties.endPoint)
    },
    startPoint () {
      return this.setDisplayName(this.track.geojson.properties.startPoint)
    },
    notValidStep () {
      return !this.validateStep()
    }
  },
  components: {
    titled,
    rating
  },
  mounted () {
    // We are coming from external import like Strava
    if (this.track.geojson.geometry.coordinates.length > 0) {
      this.initMap()
      this.addGeoJSON(this.$store.getters.uploadTrackGeoJSON)
      this.analyzed = true
    }
    if (this.track.validatedStep > 0) {
      this.initMap()
      this.analyzed = true
      this.addGeoJSON(this.track.geojson)
    }
  },
  data () {
    return {
      error: null,
      analyzed: false,
      processing: false,
      numberOfPoints: 4,
      status: {
        parsed: false,
        geojson: false,
        simplify: false
      }
    }
  },
  methods: {
    validateStepTwo () {
      if (this.name.length > 10 && this.description.length > 50) {
        return true
      }
      return false
    },
    validateStep () {
      console.log(`Currently validating Step: ${this.step}`)
      switch (this.step) {
        case 0:
        case 1:
        case 3:
          return true
        case 2:
          return this.validateStepTwo()
        case 4:
        default:
          return false
      }
    },
    initMap () {
      this.disableScrollWheelZoom()
      this.disableDragging()
      this.disableZoom()
    },
    save (id) {
      const geojson = this.$store.getters.uploadTrackGeoJSON
      this.post('upload', geojson).then(response => {
        this.$buefy.toast.open({
          message: 'Sauvegarde OK !',
          type: 'is-success'
        })
        this.$router.push({name: 'track.detail', params: {id: response.data.id}})
        this.reset()
      })
    },
    linkDisabled (s) {
      return this.$store.getters.validatedStep < s
    },
    next () {
      if (this.validateStep()) {
        this.$store.commit('incStep')
      } else {
        console.log('fail')
      }
    },
    setStep (v) {
      if (this.$store.getters.validatedStep >= v) {
        this.$store.commit('setStep', v)
      } else {
        // @TODO Handling in a better way
        console.log('not allowed')
      }
    },
    reset () {
      this.parsed = false
      this.analyzed = false
      this.success = false
      this.processing = false
      this.$store.commit('resetTrack')
    },
    onFileChange (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.processFile(files[0])
    },
    reverseGeocodingPoints (coordinates) {
      const me = this
      const fp = coordinates[0]
      const length = coordinates.length
      const lp = coordinates[length - 1]

      this.reverseGeocoding(fp[0], fp[1]).then(d => {
        me.$store.commit('setStartPoint', d.raw)
      })
      this.reverseGeocoding(lp[0], lp[1]).then(d => {
        me.$store.commit('setEndPoint', d.raw)
      })
    },
    processFile (file) {
      const me = this
      let reader = new FileReader()
      this.processing = true
      reader.readAsText(file)

      reader.onload = e => {
        // parse gpx
        const result = e.target.result
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(result, 'text/xml')
        const tracks = Array.from(xmlDoc.getElementsByTagName('trk'))

        if (tracks.length === 0) {
          this.error = 'Can not find any track data in your gpx file'
          this.processing = false
          return
        }

        // reduce tracks as segments
        let segments = tracks.reduce(function (previous, current) {
          let trkseg = Array.from(current.getElementsByTagName('trkseg'))
          return previous.concat(trkseg)
        }, [])

        // reduce segments as points
        let points = segments.reduce(function (previous, current) {
          let trkpt = Array.from(current.getElementsByTagName('trkpt'))
          return previous.concat(trkpt)
        }, [])

        // convert points as GeoJSON points
        let rawCoordinates = points.map(convertWayPointAsGeoJSONPoint)
        this.$store.commit('setTrackCoordinates', rawCoordinates)

        // reverse geocoding
        this.reverseGeocodingPoints(rawCoordinates)

        const geojson = this.$store.getters.uploadTrackGeoJSON

        // compute elevation gain
        const elevationGain = getElevationGain(geojson, me.numberOfPoints)

        // Simplify tracks
        const simplifiedGeojson = simplify(geojson, 0.00005)

        // compute distance
        const distance = haversine(geojson)

        // update display
        me.analyzed = true
        me.processing = false

        me.$store.commit('setTrackInfo', {key: 'reducedNbOfPoints', value: simplifiedGeojson.geometry.coordinates.length})
        me.$store.commit('setTrackInfo', {key: 'nbOfPoints', value: rawCoordinates.length})
        me.$store.commit('setElevationGain', elevationGain)
        me.$store.commit('setTrackInfo', {key: 'fileName', value: file.name})
        me.$store.commit('setTrackInfo', {key: 'distance', value: distance})

        // init map
        me.initMap()

        // display geoJSON
        me.addGeoJSON(simplifiedGeojson)

        me.next()
        me.$store.commit('setStepOK')
      }
    }
  }
}
</script>

<style scoped>
.label-file {
  cursor: pointer;
  color: #00b1ca;
  font-weight: bold;
}
.label-file:hover {
  color: #25a5c4;
}
#file {
  display: none;
}
#wrapper {
  height: 200px;
  margin-bottom: 20px;
}
</style>
<style lang="scss" scoped>
  @import './progress.scss'
</style>