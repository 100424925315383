<template>
  <router-link
    class="card"
    to="/"
    v-on:click.native="goTo()"
  >
    <div
      class="is-size-1 has-text-white image is-2by1"
      style="background-size: cover"
      v-bind:style="{'background-color': color, 'background-image': imageUrl}"
    >
      <i
        class="fas hvr-push"
        v-bind:class="icon"
        aria-hidden="true"
      ></i>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-5">{{ title }}</p>
        </div>
      </div>
      <div class="content">{{ subtitle }}</div>
    </div>
  </router-link>
</template>
<script>
function isFunction (functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
}

export default {
  props: ['color', 'image', 'icon', 'title', 'subtitle', 'routeName'],
  computed: {
    imageUrl () {
      if (this.image) {
        return `url(${this.image})`
      }
    }
  },
  methods: {
    goTo () {
      if (isFunction(this.routeName)) {
        this.routeName()
      } else {
        this.$router.push({ name: this.routeName })
      }
    }
  }
}
</script>
<style scoped>
.card-content {
  background-color: #FFF;
  color: #363636;
}
p.title {
  color: #363636 !important;
}
.card {
  cursor: pointer;
}
.is-4by3 {
  height: 218px;
  line-height: 218px;
}
/* Push */
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.hvr-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-push:hover,
.hvr-push:focus,
.hvr-push:active {
  -webkit-animation-name: hvr-push;
  animation-name: hvr-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
</style>


