<template>
  <div id="geocode">
    <input
      class="input"
      type="text"
      :placeholder="$t('search.city')"
      v-model="city"
    >
    <ul>
      <li
        v-for="item in items"
        v-on:click="center(item.lng, item.lat)"
      >{{item.name}}
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from 'debounce'
import bus from '../bus-event'
import api from '@/mixins/api'

const DEBOUNCE_TIME = 500

export default {
  mixins: [api],
  data () {
    return {
      city: '',
      items: []
    }
  },
  watch: {
    city (v) {
      if (v !== '') {
        this.fetchDataFromPhoton()
      }
    }
  },
  methods: {
    center (lng, lat) {
      bus.$emit('map.center', lng, lat, 14)
      this.city = ''
      this.items = []
    },
    fetchDataFromNominatim: (function () {
      this.items = []
      let items = this.items
      let url = 'https://nominatim.openstreetmap.org/search?q=' + encodeURI(this.city) + '&format=json'
      this.$http.get(url).then(response => {
        if (response.data) {
          for (let i in response.data) {
            let obj = response.data[i]
            if (obj.type !== 'administrative') {
              continue
            }
            items.push({
              name: obj.display_name,
              adminName1: obj.display_name,
              lng: obj.lon,
              lat: obj.lat
            })
          }
        }
      }).catch(e => {
      })
    }, DEBOUNCE_TIME),
    fetchDataFromPhoton: debounce(function () {
      this.items = []
      let url = 'https://photon.komoot.de/api/?q=' + encodeURI(this.city)
      this.get(url).then(response => {
        if (response.data) {
          if (!response.data.features) {
            return
          }
          for (let obj of response.data.features) {
            if (obj.properties.osm_key !== 'place') {
              continue
            }
            this.items.push({
              name: obj.properties.name + ' - ' + obj.properties.state,
              adminName1: obj.display_name,
              lng: obj.geometry.coordinates[0],
              lat: obj.geometry.coordinates[1]
            })
          }
        }
      }).catch(e => {

      })
    }, DEBOUNCE_TIME)
  }
}
</script>
<style scoped>
ul {
  background-color: #fff;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
ul li {
  cursor: pointer;
  background-color: #fff;
  padding: 8px;
}
</style>