<template>
  <div id="wrapper">
    <div id="map"></div>
  </div>
</template>

<script>
import api from '@/mixins/api'
import map from '@/mixins/map'
import bus from './bus-event'

export default {
  props: ['trackId', 'pois'],
  data () {
    return {
      milestones: {},
      milestonesMarkers: []
    }
  },
  mixins: [api, map],
  methods: {
    addMarker (lng, lat) {
      this.addStartMarker(lng, lat)
    },
    moveMarker (lng, lat) {
      this.moveStartMarker(lng, lat)
    },
    removeMilestones () {
      for (let m of this.milestonesMarkers) {
        this.mapObject.removeLayer(m)
      }
      this.milestonesMarkers = []
    },
    toggleMilestones () {
      if (this.milestonesMarkers.length === 0) {
        this.displayMilestones()
      } else {
        this.removeMilestones()
      }
    },
    displayMilestones () {
      for (let k in this.milestones) {
        const point = this.milestones[k]
        const m = this.addMilestonesWithLngLat(point[0], point[1], k)
        this.milestonesMarkers.push(m)
      }
    },
    loadMilestones (id) {
      return this.get('tracks/' + id + '/elevations').then(response => {
        for (let row of response.data) {
          const value = Math.floor(row[0] / 1000)
          if (!this.milestones[value]) {
            this.milestones[value] = [row[2], row[3]]
          }
        }
      })
    }
  },
  mounted () {
    this.disableDraggingForMobile()
    this.pois.map(poi => this.addPoi(poi))
    this.loadMilestones(this.trackId).then(() => {
      this.addButton('fa-map-marker-alt', () => {
        this.toggleMilestones()
      })
    })

    this.loadJSON(this.trackId, 0, (layer, body) => {
      const {coordinates} = body
      this.addStartMarker(coordinates[0][0], coordinates[0][1])
      this.mapObject.fitBounds(layer.getBounds())
    })
  },
  created () {
    bus.$on('map.movemarker', this.moveMarker)
  },
  beforeDestroy () {
    bus.$off('map.movemarker')
  }
}
</script>
<style scoped>
  #wrapper {
    height: 400px;
    width: 100%;
  }
</style>
<style>
  .milestone {
    display: inline-block;
    font-size: 14px;
    padding-top: 6px;
    color: #fff
  }
</style>