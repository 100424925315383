<template>
  <div class="columns is-gapless is-full-height">
    <div class="column is-two-thirds is-relative">
      <div id="map"></div>
    </div>
    <div class="column is-grey">
      <div class="tabs">
        <ul>
          <li v-bind:class="{'is-active': displayPanel == 'draw'}">
            <a @click="setDisplayPanel('draw')">
              <span class="icon is-small"><i class="fas fa-stream" aria-hidden="true"></i></span>
              <span>Tracks <span class="tag is-info"> {{ polylines.length }}</span></span>
            </a>
          </li>
          <li v-bind:class="{'is-active': displayPanel == 'display'}">
            <a @click="setDisplayPanel('display')">
              <span class="icon is-small"><i class="fas fa-desktop" aria-hidden="true"></i></span>
              <span>Displays</span>  
            </a>
          </li>
          <li v-bind:class="{'is-active': displayPanel == 'upload'}">
            <a @click="setDisplayPanel('upload')">
              <span class="icon is-small"><i class="fas fa-upload" aria-hidden="true"></i></span>
              <span>Upload</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="padded">
        <div id="draw" v-if="displayPanel=='draw'">
          <a
            @click="toggleHelp"
            class="is-padded-bottom is-size-7"
          >
            {{ toggleInformationLinkContent }}
          </a>

          <table class="table is-bordered is-fullwidth center-table is-narrow padded-top">
            <thead>
              <th></th>
              <th>{{ $t('prepare.name') }}</th>
              <th>{{ $t('prepare.distance') }} (km)</th>
            </thead>
            <tr v-for="p, idx in polylines">
              <td>
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :checked="p.selected"
                    v-on:change="select(p)"
                  >
                </label>
              </td>
              <td>
                <b-input v-model="p.name"></b-input>
              </td>
              <td class="has-text-left">{{p.getDistance() | toKm}}</td>
            </tr>
            <tr v-if="polylines.length === 0">
              <td colspan="3">-</td>
            </tr>
          </table>

          <hr>

          <selected-action
            :polylines="polylines"
            :add="addPolyline"
            :map="mapObject"
            :refreshMarkers="refreshMarkers"
            :refreshPolylines="refreshPolylines"
            :elevation="computeElevation"
            :unselect="unselect"
          />

          <hr>

          <div
            class="notification is-info animated fadeIn"
            v-if="showHelp"
          >
            <b>Mode:</b> {{ mode }} <br>
            {{ helpContent }}
          </div>
          <span v-if="selectedTrack" class="is-size-7 is-padded-bottom"><b>Approximative Elevation Gain {{ selectedTrack.elevationGain }} m</b></span>
          <apexchart width="100%" height="200" type="area" :options="chartOptions" :series="series"></apexchart>

        </div>

        <div id="display" v-if="display">

          Reverve Geocoding:<br/>
          <geocoder class=is-padded-bottom></geocoder>

          <hr>

          <div class="field">
            <b-switch v-model="displayHeatMap">{{ $t('prepare.displayHeatmap') }}</b-switch>
          </div>

          <p class="is-size-7">{{ $t('prepare.help.heatmap') }}</p>
          <hr>

          <div class="field">
            <b-switch v-model="displayGRLayer">{{ $t('prepare.displayGR') }}</b-switch>
          </div>
        </div>

        <div id="upload" v-if="displayPanel=='upload'">
          <upload :setGeoJSON="addGeoJSONFromGPXUploadedFile" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import map from '@/mixins/map'
import api from '@/mixins/api'
import filters from '@/mixins/filters'
import SelectedAction from './selected-track-action'
import { mapGetters } from 'vuex'
import L from 'leaflet/dist/leaflet-src.js'
import haversine from '@/services/haversine'
import distances from '@/services/distances'
import Geocoder from '@/components/geocoder/geocoder'
import Upload from '@/components/gpx-upload'
import simplify from 'simplify-geojson/index.js'
// import {getElevationGain} from 'geojson-elevation-gain'

function getElevationGain () {}

const ACTION_DRAW = 'draw'
const ACTION_DELETE_VERTEX = 'delete_vertex'
const ACTION_ADD_VERTEX = 'add_vertex'
const ACTION_SPLIT = 'split'
const ACTION_STANDBY = 'standby'

const DEFAULT_STYLE = {
  color: 'blue'
}

const SELECTED_STYLE = {
  color: 'red'
}

const CHART_OPTIONS = {
  chart: {
    toolbar: {
      show: false
    },
    id: 'vuechart-example'
  },
  stroke: {
    show: true,
    width: 2
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    type: 'numeric',
    labels: {
      formatter: function (value) {
        return (value / 1000).toFixed(2)
      }
    }
  },
  yaxis: {
    labels: {
      show: true
    }
  }
}

class EditablePolyline {
  constructor (polyline) {
    this.polyline = polyline
    this.selected = false
    this.name = 'No name'
    this.elevations = []
    this.elevationGain = 0
  }

  getDistance () {
    const g = this.polyline.toGeoJSON()
    return haversine(g)
  }
}

class Mode {
  constructor (name, button) {
    this.name = name
    this.button = button
    this.active = false
  }
}

export default {
  data () {
    return {
      displayPanel: 'draw',
      shape: null,
      distance: 0,
      polylines: [],
      buttons: [],
      displayHeatMap: false,
      mode: ACTION_STANDBY,
      showHelp: true,
      selectedTrack: null,
      startMarker: null,
      endMarker: null,
      displayGRLayer: false,
      chartOptions: CHART_OPTIONS,
      series: [ ]
    }
  },
  computed: {
    helpContent () {
      return this.$t(`prepare.help.${this.mode}`)
    },
    toggleInformationLinkContent () {
      if (!this.showHelp) {
        return this.$t('prepare.hideHelp')
      }
      return this.$t('prepare.showHelp')
    }
  },
  watch: {
    mode (newValue, oldValue) {
      for (let mode of this.buttons) {
        mode.button.setToggleOff()
        if (mode.name === newValue) mode.button.setToggleOn()
      }
    },
    displayHeatMap (newValue, oldValue) {
      if (newValue === false) {
        if (this.mapObject.hasLayer(this.heatmap)) {
          this.mapObject.removeLayer(this.heatmap)
        }
      } else {
        this.refreshHeatmap().then(response => {
          if (!this.mapObject.hasLayer(this.heatmap)) {
            this.mapObject.addLayer(this.heatmap)
          }
        })
      }
    },
    displayGRLayer (newValue, oldValue) {
      if (newValue === false) {
        this.removeGRLayer()
      } else {
        this.addGRLayer()
      }
    }
  },
  mixins: [map, filters, api],
  components: { SelectedAction, Geocoder, Upload },
  methods: {
    ...mapGetters(['getEdit', 'isLogged']),
    setDisplayPanel (mode) {
      this.displayPanel = mode
    },
    addGeoJSONFromGPXUploadedFile (g) {
      const simplifiedGeojson = simplify(g, 0.00009)
      const coords = simplifiedGeojson.geometry.coordinates.map(p => [p[1], p[0]])
      const editablePolyline = this.addPolyline(coords)
      editablePolyline.name = g.properties.name
      this.setDisplayPanel('draw')
      this.fitBounds(editablePolyline.polyline)
    },
    toggleHelp () {
      this.showHelp = !this.showHelp
    },
    refreshMarkers (polyline) {
      this.removeStartandStopMarker()
      if (polyline) this.addStartAndStopMarkers(polyline)
    },
    removeStartandStopMarker () {
      if (this.mapObject.hasLayer(this.startMarker)) {
        this.mapObject.removeLayer(this.startMarker)
      }

      if (this.mapObject.hasLayer(this.endMarker)) {
        this.mapObject.removeLayer(this.endMarker)
      }
    },
    addStartAndStopMarkers (polyline) {
      const coords = polyline._latlngs
      const start = coords[0]
      const end = coords[coords.length - 1]
      this.startMarker = this.addCustomMarkerWithLngLat(start.lng, start.lat, 'fa-play-circle')
      this.endMarker = this.addCustomMarkerWithLngLat(end.lng, end.lat, 'fa-stop-circle')
    },
    unselect () {
      this.series = []
      this.selectedTrack = null
    },
    select (p) {
      this.selectedTrack = p
      this.series = [
        {
          name: 'elevation',
          data: p.elevations
        }
      ]
      this.removeStartandStopMarker()
      for (let editablePolyline of this.polylines) {
        if (p === editablePolyline) continue
        editablePolyline.selected = false
        editablePolyline.polyline.setStyle(DEFAULT_STYLE)
      }
      p.selected = !p.selected
      if (p.selected === true) {
        p.polyline.setStyle(SELECTED_STYLE)
        this.addStartAndStopMarkers(p.polyline)
      } else {
        p.polyline.setStyle(DEFAULT_STYLE)
      }
    },
    getBoundsAsQueryStringForApi () {
      const bounds = this.mapObject.getBounds()
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      return 'ne_lat=' + ne.lat.toFixed(3) +
        '&ne_lon=' + ne.lng.toFixed(3) +
        '&sw_lat=' + sw.lat.toFixed(3) +
        '&sw_lon=' + sw.lng.toFixed(3)
    },
    computeElevation (p) {
      const g = p.polyline.toGeoJSON()
      const d = distances(g)

      const payload = g.geometry.coordinates.map(r => {
        return { lon: r[0], lat: r[1] }
      })

      this.srtmElevation(payload).then(response => {
        g.geometry.coordinates = g.geometry.coordinates.map((a, i) => {
          a.push(response.data[i])
          return a
        })
        p.elevationGain = getElevationGain(g, 3)
        const data = response.data.map((v, i) => {
          return {y: v, x: d[i]}
        })
        p.elevations = data
        this.series = [
          {
            name: 'elevation',
            data: data
          }
        ]
      }).catch(e => {
        if (e.response && e.response.status === 416) {
          this.$buefy.toast.open({
            message: this.$t('prepare.strmRangeExceeded'),
            type: 'is-danger'
          })
        } else {
          this.$buefy.toast.open({
            message: this.$t('common.operation.fail'),
            type: 'is-danger'
          })
        }
      })
    },
    refreshHeatmap () {
      if (!this.displayHeatMap) return
      let qs = this.getBoundsAsQueryStringForApi()
      return this.get('heatmap?' + qs, {}).then(response => {
        this.addHeatMap(response.data)
      }).catch(e => {
        if (e.response && e.response.status === 416) {
          this.$buefy.toast.open({
            message: this.$t('prepare.rangeExceeded'),
            type: 'is-danger'
          })
        } else {
          this.$buefy.toast.open({
            message: this.$t('common.operation.fail'),
            type: 'is-danger'
          })
        }
      })
    },
    // Move this method in the map Mixin
    addCustomButton (icon, callback, title) {
      L.NewControl = L.Control.extend({
        options: {
          position: 'topleft'
        },
        onAdd (map) {
          const container = L.DomUtil.create('div', 'leaflet-control leaflet-bar')
          const link = L.DomUtil.create('a', '', container)
          link.href = '#'
          if (title) {
            link.title = title
          }
          link.innerHTML = `<i class="fas ${icon}"></a>`

          // Inspired by Leaflet Code
          // https://github.com/Leaflet/Leaflet/blob/8f3a353d84909f7b6cf27d6a37b162af01cc2dc0/src/control/Control.Zoom.js#L65
          L.DomEvent.disableClickPropagation(container)
          L.DomEvent
            .on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop, this)
            .on(link, 'click', () => {
              callback(map)
            })
          return container
        }
      })
      const t = new L.NewControl()
      t.setToggleOn = () => {
        L.DomUtil.addClass(t._container.children[0], 'leaflet-button-toggleon')
      }
      t.setToggleOff = () => {
        L.DomUtil.removeClass(t._container.children[0], 'leaflet-button-toggleon')
      }
      this.mapObject.addControl(t)
      return t
    },
    refreshPolylines () {
      // removing empty shape
      this.polylines = this.polylines.filter(p => p.polyline._latlngs.length > 0)
    },
    addPolyline (latlngs) {
      const p = L.polyline(latlngs, DEFAULT_STYLE)
      p.addTo(this.mapObject)
      p.enableEdit()
      const editablePolyline = new EditablePolyline(p)
      this.polylines.push(editablePolyline)
      return editablePolyline
    }
  },
  mounted () {
    if (!this.isLogged()) {
      this.$router.push({ name: 'home' })
    }

    // check if we come for editing route
    const toEdit = this.getEdit()

    // Editable trace coming from track detail
    if (toEdit) {
      const latlngs = toEdit.coordinates.map(p => [p[1], p[0]])
      const editablePolyline = this.addPolyline(latlngs)
      this.fitBounds(editablePolyline.polyline)
    }

    // on location found
    this.mapObject.on('locationfound', l => {
      const lat = l.latlng.lat
      const lng = l.latlng.lng
      this.setCenterToLngAndLat(lng, lat, 14)
      this.$snackbar.open(this.$t('prepare.locationFound'))
    })

    // on location error
    this.mapObject.on('locationerror', l => {
      this.$buefy.toast.open({
        message: this.$t('prepare.localisationError'),
        type: 'is-danger'
      })
    })

    this.mapObject.on('moveend', this.refreshHeatmap)

    // Subscribe to events
    this.mapObject.on('editable:vertex:rawclick', (e, layer) => {
      const v = e.vertex
      switch (this.mode) {

        case ACTION_DELETE_VERTEX:
          if (!v.editor.vertexCanBeDeleted(v)) return
          v.delete()
          break

        case ACTION_ADD_VERTEX:
          v.continue()
          break

        case ACTION_SPLIT:

          // get two splitted segments and the originale shape
          const segments = v.split()

          // removing current feature
          v.editor.deleteShapeAt(e.latlng)

          // creating two polyline from segments
          const p1 = L.polyline(segments[0], DEFAULT_STYLE)
          p1.addTo(this.mapObject)
          p1.enableEdit()

          const p2 = L.polyline(segments[1], DEFAULT_STYLE)
          p2.addTo(this.mapObject)
          p2.enableEdit()

          // Feeding the list
          this.polylines.push(new EditablePolyline(p1))
          this.polylines.push(new EditablePolyline(p2))

          // Removing start and stop marker
          if (this.polylines.filter(p => p.selected).length > 0) {
            this.removeStartandStopMarker()
          }

          // Removing empty polyline
          this.refreshPolylines()

          break

        default:
          break
      }
    })

    this.mapObject.on('editable:drawing:end', map => {
      console.log('stop drawing')
      this.mode = ACTION_STANDBY
      this.refreshPolylines()
    })

    this.mapObject.on('editable:drawing:start', map => {
      console.log('start drawing')
      this.mode = ACTION_DRAW
    })

    this.mapObject.on('editable:vertex:dragend', map => {
      const selected = this.polylines.filter(p => p.selected === true)
      if (selected.length > 0) {
        this.removeStartandStopMarker()
        this.addStartAndStopMarkers(selected[0].polyline)
      }
    })

    // Simple Button

    this.addCustomButton('fa-crosshairs', map => {
      this.mapObject.locate()
      this.$buefy.toast.open({
        message: this.$t('prepare.localisationSearchInProgress'),
        type: 'is-info'
      })
    }, this.$t('prepare.tooltip.findCurrentPosition'))

    // Drawing Action Buttons

    const stopDrawing = map => {
      if (this.mode === ACTION_DRAW) {
        map.editTools.stopDrawing()
      }
    }

    const buttonStandBy = this.addCustomButton('fa-mouse-pointer', map => {
      stopDrawing(map)
      this.mode = ACTION_STANDBY
    }, this.$t('prepare.tooltip.select'))

    const buttonDraw = this.addCustomButton('fa-pen', map => {
      const p = map.editTools.startPolyline(null, DEFAULT_STYLE)
      const editablePolyline = new EditablePolyline(p)
      this.polylines.push(editablePolyline)
    }, this.$t('prepare.tooltip.draw'))

    const buttonDeleteVertex = this.addCustomButton('fa-times', map => {
      stopDrawing(map)
      this.mode = ACTION_DELETE_VERTEX
    }, this.$t('prepare.tooltip.deleteVertex'))

    const buttonAddVertex = this.addCustomButton('fa-plus-square', map => {
      stopDrawing(map)
      this.mode = ACTION_ADD_VERTEX
    }, this.$t('prepare.tooltip.addVertex'))

    const buttonSplit = this.addCustomButton('fa-cut', map => {
      stopDrawing(map)
      this.mode = ACTION_SPLIT
    }, this.$t('prepare.tooltip.split'))

    this.buttons = [
      new Mode(ACTION_STANDBY, buttonStandBy),
      new Mode(ACTION_DRAW, buttonDraw),
      new Mode(ACTION_DELETE_VERTEX, buttonDeleteVertex),
      new Mode(ACTION_ADD_VERTEX, buttonAddVertex),
      new Mode(ACTION_SPLIT, buttonSplit)
    ]

    buttonStandBy.setToggleOn()
  }
}
</script>
<style scoped>
.is-full-height {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 52px) !important;
}
.is-relative {
  position: relative;
}
.padded {
  padding: 20px !important;
}

a.is-padded-bottom {
  display: inline-block;
}
.is-grey {
  background-color: #f5f5f5;
}
#map {
  width: 100%;
  height: 100%;
}
.center-table td {
  text-align: center;
  vertical-align: middle;
}
</style>
<style>
.padded-top {
  padding-top: 20px;
}
.is-padded-bottom {
  margin-bottom: 15px;
}
.leaflet-button-toggleon {
  background-color: #ddd !important;
}
.leaflet-button-toggleoff {
  background-color: #fff !important;
}
</style>