<template>
  <section class="section">
    <div class="container">
      <track-table :tracks="tracks"></track-table>
      <pagination
        :total="totalCount"
        :per-page="perPage"
        :update="update"
      ></pagination>
    </div>
  </section>
</template>
<script>
import api from '@/mixins/api'
import Pagination from '@/components/pagination/pagination'
import TrackTable from '@/components/search-common/table'

export default {
  mixins: [api],
  components: {
    Pagination,
    TrackTable
  },
  data () {
    return {
      tracks: [],
      totalCount: 0,
      offset: 0,
      perPage: 20

    }
  },
  mounted () {
    this.keyword = this.$route.query.q
    this.search()
  },
  methods: {
    update (offset) {
      this.offset = offset
      this.search()
    },
    search () {
      this.get('users/me/tracks?offset=' + this.offset + '&limit=' + this.perPage).then(response => {
        this.tracks = response.data
        this.totalCount = response.headers['x-total-count']
      })
    }
  }
}
</script>
