<template>
  <div
    class="navbar-item has-dropdown"
    :class="{'is-active': active}"
    v-if="isLogged"
  >
    <a
      class="is-white navbar-link"
      @click="toggle"
      ref="dropdownMenu"
    >
      <i class="fas fa-user"></i>
    </a>
    <div class="navbar-dropdown is-right">
      <router-link
        :to="{name: 'my.profile'}"
        class="navbar-item"
      >
        {{ $t('menu.myProfile') }}
      </router-link>
      <router-link
        :to="{name: 'my.tracks'}"
        class="navbar-item is-white"
      >
        {{ $t('menu.myTracks') }}
      </router-link>
      <router-link
        :to="{name: 'connector.strava'}"
        class="navbar-item is-white"
      >
        Connect to Strava
      </router-link>
      <a
        class="navbar-item is-white"
        @click="signOut()"
      >
        <span>{{ $t('menu.logout') }}</span>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isLogged'])
  },
  data () {
    return {
      active: false
    }
  },
  created () {
    document.addEventListener('click', this.documentClick)
  },
  destroyed () {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    ...mapActions(['logout']),
    documentClick (e) {
      const el = this.$refs.dropdownMenu
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.active = false
      }
    },
    toggle () {
      this.active = !this.active
    },
    signOut () {
      this.logout()
      this.$router.push({ path: '/' })
    }
  }
}
</script>
<style scoped>
.has-dropdown {
  align-items: center !important;
}
.navbar-dropdown {
  background-color: #192532;
}
</style>