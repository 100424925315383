import axios from 'axios'
import jwtDecode from 'jwt-decode'

const TOKEN_KEY = 'token'
const geojson = () => {
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: []
    },
    properties: {
      'description': '',
      'name': '',
      'rating': {},
      'elevationGain': 0,
      'startPoint': null,
      'endPoint': null,
      'providerId': null,
      'providerName': null
    }
  }
}

const init = () => {
  return !!window.localStorage.getItem(TOKEN_KEY)
}

const initJwt = () => {
  const data = window.localStorage.getItem(TOKEN_KEY)
  if (data) {
    const jwt = JSON.parse(data)
    return jwt
  }
}

const initTargetPath = () => {
  return {
    name: null,
    params: null
  }
}

const state = {
  toEdit: null,
  search: {
    selectedTrack: null
  },
  jwt: initJwt(),
  logged: init(),
  targetPath: initTargetPath(),
  track: {
    cantContinue: true,
    validatedStep: 0,
    currentStep: 0,
    info: {},
    geojson: geojson()
  }
}

const actions = {
  setEdit ({commit}, geojson) {
    commit('toEdit', geojson)
  },
  storeToken (token) {
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token))
  },
  login ({commit}) {
    commit('login')
  },
  logout ({commit}) {
    window.localStorage.removeItem(TOKEN_KEY)
    commit('logout')
  },
  setTargetPath ({commit}, data) {
    commit('setTargetPath', data)
  },
  signIn ({commit}, data) {
    const payload = {
      email: data.email,
      password: data.password
    }
    return axios.post(process.env.VUE_APP_API + 'login', payload).then(response => {
      const jwtData = jwtDecode(response.data.token)
      const data = {
        token: response.data.token,
        userId: jwtData.userId,
        expiredAt: jwtData.expiredAt
      }
      if (window._paq) {
        window._paq.push(['setUserId', jwtData.userId])
        window._paq.push(['trackPageView'])
        window._paq.push(['trackEvent', 'Login', jwtData.userId])
      }

      window.localStorage.setItem(TOKEN_KEY, JSON.stringify(data))
      commit('login')
      commit('jwt', data)
      return response
    })
  }
}

const getters = {
  isLogged: state => state.logged,
  step: state => state.track.currentStep,
  validatedStep: state => state.track.validatedStep,
  uploadTrackGeoJSON: state => state.track.geojson,
  uploadTrack: state => state.track,
  uploadTrackInfo: state => state.track.info,
  cantContinue: state => state.track.cantContinue,
  getEdit: state => state.toEdit,

  getUserId (state) {
    if (state.jwt && state.jwt.userId) {
      return state.jwt.userId
    }
  },

  getTargetPath: state => state.targetPath,
  getJwt: state => state.jwt,

  // search
  searchSelectedTrack: state => state.search.selectedTrack
}

const mutations = {
  incStep: state => {
    state.track.currentStep = state.track.currentStep + 1
    if (state.track.currentStep > state.track.validatedStep) {
      state.track.validatedStep = state.track.currentStep
    }
    state.track.cantContinue = true
  },
  login: (state) => {
    state.logged = true
  },
  logout: (state) => {
    state.logged = false
  },
  jwt: (state, jwt) => {
    state.jwt = jwt
  },
  toEdit: (state, geojson) => {
    state.toEdit = geojson
  },
  setStep: (state, v) => {
    state.track.currentStep = v
  },
  setTrackCoordinates: (state, payload) => {
    state.track.geojson.geometry.coordinates = payload
  },
  setTrackName: (state, payload) => {
    state.track.geojson.properties.name = payload
  },
  setTrackDescription: (state, payload) => {
    state.track.geojson.properties.description = payload
  },
  resetTrack: state => {
    state.track.validatedStep = 0
    state.track.currentStep = 0
    state.track.geojson = geojson()
  },
  setRating: (state, rating) => {
    state.track.geojson.properties.rating[rating.name] = rating.value
  },
  setStartPoint: (state, startPoint) => {
    state.track.geojson.properties.startPoint = startPoint
  },
  setEndPoint: (state, endPoint) => {
    state.track.geojson.properties.endPoint = endPoint
  },
  setElevationGain: (state, ele) => {
    state.track.geojson.properties.elevationGain = ele
  },
  setProperty: (state, obj) => {
    state.track.geojson.properties[obj.key] = obj.value
  },
  setTrackInfo: (state, obj) => {
    state.track.info[obj.key] = obj.value
  },
  setStepOK: (state) => {
    state.track.cantContinue = false
  },
  setStepNok: (state) => {
    state.track.cantContinue = true
  },
  setTargetPath (state, data) {
    state.targetPath.params = data.params
    state.targetPath.name = data.name
  },

  // search
  setSearchSelectedTrack: (state, track) => {
    state.search.selectedTrack = track
  },
  cancelSearchSelectedTrack: state => {
    state.search.selectedTrack = null
  }
}

export const store = {
  actions,
  getters,
  mutations,
  state
}
