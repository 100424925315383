<template>
  <section class="section">
    <div class="container">
      <div
        class="columns is-centered margin"
        v-if="done"
      >
        <div class="column is-three-quarters">
          <div
            class="notification is-primary"
            v-html="$t('register.success')"
          ></div>
        </div>
      </div>

      <div
        class="columns is-centered margin"
        v-else
      >
        <div class="column is-three-quarters">
          <h3 class="title">
            <i class="fas fa-user"></i>
            {{ $t('register.accountCreation')}}</h3>
          <div
            class="notification is-danger animated fadeIn"
            v-if="error"
          >{{error}}</div>
          <div
            class="notification is-sucess"
            v-if="done"
          >{{ $t('register.accountCreated') }}</div>

          <div class="field">
            <label class="label">{{ $t('register.username') }}</label>

            <p class="control has-icons-left">
              <input
                class="input"
                type="text"
                :placeholder="$t('register.name')"
                v-model="name"
              >
              <span class="icon is-small is-left">
                <i class="fas fa-user"></i>
              </span>
            </p>
            <!--p class="help is-danger">This username is available</p-->
          </div>

          <div class="field">
            <label class="label">{{ $t('register.email') }}</label>
            <p class="control has-icons-left">
              <input
                class="input"
                type="email"
                :placeholder="$t('register.email')"
                v-model="email"
              >
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </p>
            <p
              class="help is-danger"
              v-if="!emailValid"
            >{{ $t('register.emailNotValid') }}</p>
          </div>

          <div class="field">
            <label class="label"> {{ $t('register.password') }} </label>
            <p class="control has-icons-left">
              <input
                class="input"
                type="password"
                :placeholder="$t('register.password')"
                v-model="password"
              >
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </p>
            <p
              class="help is-info"
              v-if="passwordNotCompliant"
            >{{ $t('register.passwordSize') }}</p>
          </div>

          <div class="field">
            <label class="label">{{ $t('register.passwordVerification') }}</label>
            <p class="control has-icons-left">
              <input
                class="input"
                type="password"
                :placeholder="$t('register.passwordAgain')"
                v-model="passwordCheck"
              >
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </p>
            <p
              class="help is-danger"
              v-if="passwordNotMatch"
            >{{ $t('register.passwordDoesntMatch') }}</p>
          </div>

          <div class="field">
            <p class="control">
              <button
                class="button is-success"
                @click="signUp()"
                :disabled="!isValid"
              >{{ $t('register.signup') }}</button>
            </p>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/mixins/api'
import checkEmail from '@/services/validate-email'

export default {
  mixins: [api],
  computed: {
    emailValid () {
      return this.validateEmail(this.email)
    },
    passwordNotCompliant () {
      return this.password.length < 8
    },
    passwordNotMatch () {
      if (this.password !== '') {
        return this.password !== this.passwordCheck
      }
      return false
    },
    isValid () {
      if (this.password === '') {
        return false
      }
      if (this.password !== this.passwordCheck) {
        return false
      }
      if (!this.emailValid) {
        return false
      }
      return true
    }
  },
  data () {
    return {
      email: '',
      name: '',
      password: '',
      passwordCheck: '',
      done: false,
      error: null
    }
  },
  methods: {
    validateEmail (email) {
      return checkEmail(email)
    },
    signUp () {
      const data = {
        email: this.email,
        name: this.name,
        password: this.password
      }
      this.post('register', data).then(response => {
        window._paq.push(['trackEvent', 'Register', this.email])
        this.done = true
      }).catch(e => {
        if (e.response.status === 409) {
          this.error = this.$t('register.accountAlreadyExists')
        } else {
          this.error = e
        }
      })
    }
  }
}
</script>
<style scoped>
.margin {
  margin-top: 40px;
}
</style>