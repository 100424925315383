<script>
import axios from 'axios'
import Nprogress from 'nprogress'
import { mapGetters, mapActions } from 'vuex'

const host = process.env.VUE_APP_API
const refreshLimit = 10 * 60

function needRefresh (expiredAt) {
  const delta = expiredAt - (Date.now() / 1000)
  return delta <= refreshLimit
}

class Call {
  constructor (url, params, body) {
    this.url = url
    if (!params) {
      this.params = {}
    } else {
      this.params = params
    }
    this.body = body
    this.externalCall = false
  }

  getUrl () {
    if (this.url.startsWith('http') || this.url.startsWith('https')) {
      this.externalCall = true
      return this.url
    } else {
      return host + this.url
    }
  }

  getParams (jwt, forceJwt) {
    if ((jwt && !this.externalCall) || forceJwt) {
      if (!this.params.headers) this.params.headers = {}
      this.params.headers['Authorization'] = 'Bearer ' + jwt.token
    }
    return this.params
  }

  getBody () {
    return this.body
  }
}

const startProgress = () => Nprogress.start()
const endProgress = () => Nprogress.done()

export default {
  methods: {
    ...mapGetters(['getJwt']),
    ...mapActions(['logout']),
    url (url) {
      const call = new Call(url)
      return call.getUrl()
    },
    call (verb, url, params, body) {
      const call = new Call(url, params, body)

      startProgress()
      let p = []

      let jwt

      if (!call.externalCall) {
        jwt = this.getJwt()
        if (jwt && needRefresh(jwt.expiredAt)) {
          this.logout()
          jwt = null
        }
      }

      if (verb === 'get' || verb === 'delete') {
        p = [call.getUrl(), call.getParams(jwt)]
      } else {
        p = [call.getUrl(), call.getBody(), call.getParams(jwt)]
      }

      return axios[verb](...p).then(response => {
        endProgress()
        return response
      }).catch(e => {
        endProgress()
        throw e
      })
    },
    get (url, params) {
      return this.call('get', url, params)
    },
    post (url, body, params) {
      return this.call('post', url, params, body)
    },
    put (url, body, params) {
      return this.call('put', url, params, body)
    },
    patch (url, body, params) {
      return this.call('patch', url, params, body)
    },
    delete (url, params) {
      return this.call('delete', url, params)
    },
    srtmElevation (payload) {
      const call = new Call(process.env.VUE_APP_ELEVATION, null, payload)
      startProgress()
      let jwt = this.getJwt()
      if (jwt && needRefresh(jwt.expiredAt)) {
        this.logout()
        jwt = null
      }
      return axios.post(call.getUrl(), call.getBody(), call.getParams(jwt, true)).then(response => {
        endProgress()
        return response
      }).catch(e => {
        endProgress()
        throw e
      })
    }
  }
}
</script>