<template>
  <section class="section">
    <div class="container">
      <div class="is-hidden-desktop">
        <p class="control has-addons">
          <input
            class="input"
            type="text"
            v-model="keyword"
            @keyup.enter="search"
            :placeholder="$t('menu.keywordSearch')"
          >
        </p>
        &nbsp;
        <p class="control">
          <button
            class="button is-info is-fullwidth"
            @click="search"
          >
            <span class="fas fa-search"></span>
          </button>
        </p>
      </div>
      <track-list
        :tracks="tracks"
        title="search"
        :keyword="keyword"
        class="margin"
        :hideTitle="true"
      />
      <pagination
        class="margin"
        :total="totalCount"
        :per-page="perPage"
        :update="update"
      />
    </div>
  </section>
</template>
<script>
import api from '@/mixins/api'
import seo from '@/mixins/seo'
import Pagination from '@/components/pagination/pagination'
import TrackList from '@/components/search-common/listing'

export default {
  mixins: [api, seo],
  components: {
    Pagination,
    TrackList
  },
  data () {
    return {
      tracks: [],
      totalCount: 0,
      offset: 0,
      perPage: 20,
      keyword: ''
    }
  },
  mounted () {
    this.keyword = this.$route.query.q
    this.search()
  },
  methods: {
    update (offset) {
      this.offset = offset
      this.search()
    },
    search () {
      window.scrollTo(0, 0)
      this.tracks = []
      this.get('search?q=' + this.keyword + '&offset=' + this.offset + '&limit=' + this.perPage).then(response => {
        this.tracks = response.data
        this.totalCount = response.headers['x-total-count']
      })
    }
  },
  watch: {
    '$route.query.q' () {
      this.offset = 0
      this.limit = 0
      this.keyword = this.$route.query.q
      this.search()
    }
  }
}
</script>
<style scoped>
.margin {
  margin-top: 20px;
}
</style>