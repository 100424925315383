<template>
  <div class="flexcontent image is-4by3">
    <img
      :src="placeholder"
      class="placeholder"
      :class="{'transparent': loaded}"
    >
    <img
      :src="src"
      class="target"
      :class="{'transparent': !loaded}"
      @load="load"
    >
  </div>
</template>

<script>
export default {
  props: ['src'],
  data () {
    return {
      placeholder: 'https://place-hold.it/680x512',
      loaded: false
    }
  },
  methods: {
    load () {
      this.loaded = true
    },
    error () {
    }
  },
  mounted () {
  },
  destroyed () {
  }
}
</script>
<style scoped>
.flexcontent img {
  -webkit-transition: opacity 0.7s ease-in-out;
  -moz-transition: opacity 0.7s ease-in-out;
  -o-transition: opacity 0.7s ease-in-out;
  transition: opacity 0.7s ease-in-out;
}
.placeholder {
  position: absolute;
  left: 0;
  height: auto;
  z-index: 1;
}
.target {
  position: absolute;
  left: 0;
  height: auto;
  z-index: 2;
}
.flexcontent {
  position: relative;
}
.transparent {
  opacity: 0;
}
</style>