<template>
  <div>
    <div class="container margin">
      <div class="notification is-danger" v-if="error">
        {{ error }}
      </div>
      <div class="notification is-success" v-if="success">
        {{ success }}
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/mixins/api'

export default {
  mixins: [api],
  data () {
    return {
      code: '',
      loader: null,
      success: '',
      error: null
    }
  },
  methods: {
    valid () {
      this.post('email-validation', { code: this.code }).then(response => {
        this.loader.close()
        this.success = this.$t('emailValidation.success')
      }).catch(e => {
        this.loader.close()
        if (e.response.status === 401) {
          this.error = this.$t('magiclink.codeHasExpired')
        } else if (e.response.status === 404) {
          this.error = this.$t('magiclink.codeDoesntExist')
        }
      })
    }
  },
  mounted () {
    this.loader = this.$buefy.loading.open({
      container: null
    })
    this.code = this.$route.params.code
    this.valid()
  }
}
</script>
<style>
.margin {
  margin-top: 15px !important
}
</style>

