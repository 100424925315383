<template>
  <div class="gallery">
    <a
      :id="picture.id"
      :href="picture.url"
      :data-caption="picture.caption"
      v-for="picture in pictures"
      class="thumbnail"
    >
      <img :src="picture.thumbnail">
    </a>
  </div>
</template>
<script>
export default {
  props: ['pictures']
}
</script>
<style scoped>
.thumbnail {
  margin: 0 4px 0 0;
  display: inline-block;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 180px;
  width: 180px;
}
</style>
