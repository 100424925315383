<template>
  <div>
    <section
      class="hero is-fullheight has-text-centered"
      v-if="notLogged"
    >
      <div class="hero-body">
        <div class="container">
          <div class="title">
            <a
              class="button is-info is-large"
              :href="connect"
            >
              <span class="icon is-medium">
                <i class="fab fa-strava"></i>
              </span>
              <span>Connect to your Strava account</span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <div
      v-if="logged"
      class="container"
    >
      <h3 class="title">
        <span class="icon is-medium">
          <i class="fab fa-strava"></i>
        </span>
        Strava Activities</h3>
      <table class="table is-fullwidth is-bordered is-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Distance (km)</th>
            <th>Elevation Gain (m)</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tr v-for="activity in activities">
          <td>
            <a
              target="_blank"
              :href="'https://www.strava.com/activities/' + activity.id"
            >
              {{ activity.name }}
            </a>
          </td>
          <td>{{ activity.distance | toKm }}</td>
          <td>{{ activity.total_elevation_gain }}</td>
          <td>{{ activity.start_date | formatDate }}</td>
          <td>
            <a
              class="button is-info"
              @click="load(activity)"
            >
              <span>Import</span>
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import api from '@/mixins/api'
import filters from '@/mixins/filters'
import ReverseGeocoding from '@/mixins/reverse-geocoding'
import axios from 'axios'

const host = 'https://www.strava.com/api/v3/'

export default {
  data () {
    return {
      logged: false,
      activities: []
    }
  },
  mixins: [api, filters, ReverseGeocoding],
  mounted () {
    const token = window.localStorage.getItem('stravaToken')

    if (token) {
      this.logged = true
      this.getActivities()
      return
    }

    const code = this.$route.query.code
    if (code) {
      this.getToken(code)
    }
  },
  computed: {
    notLogged () {
      return !this.logged
    },
    connect () {
      return this.url('strava/connect')
    }
  },
  methods: {
    toGeojson (payload) {
      let latlng = []
      let ele = []
      for (const obj of payload) {
        if (obj.type === 'latlng') {
          latlng = obj.data
        }
        if (obj.type === 'altitude') {
          ele = obj.data
        }
      }
      // reverse latitude and longitude as geosjon is lonlat
      const coords = latlng.map((el, i) => [el[1], el[0], ele[i]])
      return coords
    },
    load (activity) {
      const id = activity.id
      const j = window.localStorage.getItem('stravaToken')
      const token = JSON.parse(j)
      let params = {}
      params.headers = {}
      params.headers['Authorization'] = 'Bearer ' + token
      axios.get(host + 'activities/' + id + '/streams/latlng,altitude', params).then(response => {
        const payload = response.data
        const coords = this.toGeojson(payload)

        // get first point
        const fp = coords[0]
        const length = coords.length
        // get last point
        const lp = coords[length - 1]

        // reverse geocoding start_point
        this.reverseGeocoding(fp[0], fp[1]).then(d => {
          this.$store.commit('setStartPoint', d.raw)
        })
        // reverse geocoding end_point
        this.reverseGeocoding(lp[0], lp[1]).then(d => {
          this.$store.commit('setEndPoint', d.raw)
        })

        // commit in store some data
        this.$store.commit('setTrackCoordinates', coords)
        this.$store.commit('setElevationGain', parseInt(activity.total_elevation_gain, 10))
        this.$store.commit('setTrackInfo', {key: 'distance', value: activity.distance})
        this.$store.commit('setProperty', {key: 'providerName', value: 'strava'})
        this.$store.commit('setProperty', {key: 'providerId', value: activity.id.toString()})
        this.$store.commit('incStep')
        this.$store.commit('setStepOK')

        // redirect to the uplaod form
        this.$router.push({ name: 'upload' })
      }).catch(e => {
      })
    },
    getToken (code) {
      const lg = this.$route.params.lg
      this.get(`strava/callback?code=${code}&lg=${lg}`).then(response => {
        window.localStorage.setItem('stravaToken', JSON.stringify(response.data.token))
        this.logged = true
        this.getActivities()
      }).catch(e => {
      })
    },
    getActivities () {
      const j = window.localStorage.getItem('stravaToken')
      const token = JSON.parse(j)
      let params = {}
      params.headers = {}
      params.headers['Authorization'] = 'Bearer ' + token
      axios.get(host + 'athlete/activities', params).then(response => {
        this.activities = response.data
      }).catch(e => {
        window.localStorage.removeItem('stravaToken')
        this.logged = false
      })
    }
  }
}
</script>
