<template>
  <div class="card" @click="goToSelectedTrack(track.id)">
    <div class="card-image">
      <figure class="image is-4by3">
        <img :src="getMediaURL(track.picture)">
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-5">{{ track.name | ellipsis}}</p>
        </div>
      </div>
      <div class="content">
        <strong>{{ track.startAddress.city }}</strong><br>
        {{ track.distance | toKm }} km - {{ track.elevationGain }} m
      </div>
    </div>
  </div>
</template>
<script>
import Filters from '@/mixins/filters'

export default {
  mixins: [Filters],
  props: ['track'],
  filters: {
    ellipsis (val) {
      const size = 25
      if (val.length < size) return val
      return val.substring(0, size) + '...'
    }
  },
  methods: {
    getMediaURL: (file) => process.env.VUE_APP_RESIZE + '600/400/' + file,
    goToSelectedTrack (id) {
      this.$router.push({name: 'track.detail', params: {id: id}})
    }
  }
}
</script>
<style scoped>
p.title {
  color: #363636 !important;
}
.card {
  cursor: pointer;
}
</style>


