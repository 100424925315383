<template>
  <div
    id="video"
    v-if="video.length > 0"
  >
    <iframe
      :src="video"
      width="100%"
      height="400"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
export default {
  props: ['video']
}
</script>
