<template>
  <section class="section">
    <div class="container">
      <h3 class="title margin-top">
        <span class="icon is-medium">
          <i class="fas fa-user"></i>
        </span>
        {{ $t('profile.user') }}
      </h3>
      <div v-if="user">
        <p>{{ $t('profile.name') }}: {{ user.name }}</p>
        <p>{{ $t('profile.memberSince') }}: {{ user.creation | formatDate }}</p>
      </div>

      <h3 class="title margin-top">
        <span class="icon is-medium">
          <i class="fas fa-globe-europe"></i>
        </span>
        {{ $t('profile.activityHeatMap') }}
      </h3>
      <div id="wrapper">
        <div id="map"></div>
      </div>

      <track-table
        :tracks="tracks"
        :title="$t('profile.tracks')"
        :display-downloads="true"
        class="margin-top"
      />
      <pagination
        :total="totalCount"
        :per-page="perPage"
        :update="update"
      />
    </div>
    </section>
</template>
<script>
import api from '@/mixins/api'
import filters from '@/mixins/filters'
import seo from '@/mixins/seo'
import map from '@/mixins/map'
import Pagination from '@/components/pagination/pagination'
import TrackTable from '@/components/search-common/table'

export default {
  mixins: [api, seo, map, filters],
  components: {
    Pagination,
    TrackTable
  },
  data () {
    return {
      tracks: [],
      totalCount: 0,
      offset: 0,
      perPage: 20,
      userId: null,
      user: null
    }
  },
  mounted () {
    this.disableDragging()
    this.disableZoom()
    this.disableScrollWheelZoom()
    this.setCenterToLngAndLat(2.5, 47, 5)
    this.userId = this.$route.params.userId
    this.search()
    this.getheatmap()
    this.getUser()
  },
  methods: {
    update (offset) {
      this.offset = offset
      this.search()
    },
    getUser () {
      this.get(`users/${this.userId}`).then(response => {
        this.user = response.data
        this.setTitle(`${this.user.name} - Page utilisateur`)
      })
    },
    getheatmap () {
      this.get(`users/${this.userId}/heatmap`).then(response => {
        this.addHeatMap(response.data, {
          minOpacity: 0.4,
          max: 0.7,
          radius: 8,
          blur: 6
        })
      })
    },
    search () {
      this.get(`users/${this.userId}/tracks?offset=${this.offset}&limit=${this.perPage}`).then(response => {
        this.tracks = response.data
        this.totalCount = response.headers['x-total-count']
      })
    }
  }
}
</script>
<style scoped>
#wrapper {
  height: 400px;
  width: 100%;
}
.margin-top {
  margin-top: 20px;
}
</style>