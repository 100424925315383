<template>
  <nav
    class="navbar"
    v-bind:class="{'menu-dark' : dark === true}"
  >
    <div class="container is-fluid">
      <div class="navbar-brand">
        <router-link
          to="/"
          class="navbar-item is-link logo"
        >la-<span class="green">trace</span>.com</router-link>
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          @click="showNav = !showNav"
          :class="{ 'is-active': showNav }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarMenu"
        class="navbar-menu"
        :class="{ 'is-active': showNav }"
      >
        <div class="navbar-end">
          <div class="navbar-item is-hidden-touch">
            <p class="control has-addons">
              <input
                class="input"
                type="text"
                v-model="q"
                @keyup.enter="search"
                :placeholder="$t('menu.keywordSearch')"
              >
            </p>
            &nbsp;
            <p class="control">
              <button
                class="button is-info"
                @click="search"
              >
                <span class="fas fa-search"></span>
              </button>
            </p>
          </div>

          <span
            class="navbar-item"
            v-if="isNotLogged()"
          >
            <router-link
              :to="{ name: 'register' }"
              class="is-white"
            >
              <span class="icon">
                <i class="fas fa-user"></i>
              </span>
              <span>{{ $t('menu.register') }}</span>
            </router-link>
          </span>
          <span
            class="navbar-item"
            v-if="isNotLogged()"
          >
            <a
              class="is-white"
              @click="login()"
            >
              <span class="icon">
                <i class="fas fa-user"></i>
              </span>
              <span>{{ $t('menu.login') }}</span>
            </a>
          </span>
          <span class="navbar-item is-hidden-desktop">
            <router-link
              :to="{ name: 'search.keyword' }"
              class="is-white"
            >
              <span class="icon">
                <i class="fas fa-search"></i>
              </span>
              <span>{{ $t('menu.search') }}</span>
            </router-link>
          </span>
          <span class="navbar-item is-hidden-touch">
            <router-link
              :to="{ name: 'search.map' }"
              class="is-white"
            >
              <span class="icon">
                <i class="fas fa-map"></i>
              </span>
              <span>{{ $t('menu.search') }}</span>
            </router-link>
          </span>

          <span
            class="navbar-item"
            v-if="isLogged"
          >
            <router-link
              :to="{ name: 'upload' }"
              class="is-white"
            >
              <span class="icon">
                <i class="fas fa-map"></i>
              </span>
              <span>{{ $t('menu.upload') }}</span>
            </router-link>
          </span>

          <span class="navbar-item">
            <a
              class="is-white"
              href="https://blog.la-trace.com"
              target="_blank"
            >
              <span class="icon">
                <i class="fas fa-user"></i>
              </span>
              <span>{{ $t('menu.blog') }}</span>
            </a>
          </span>

          <dropdown v-if="isLogged"></dropdown>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import dropdown from '@/components/navbar/user-dropdown'
import Login from '@/components/login/login-modal'
import { mapGetters } from 'vuex'

export default {
  props: ['dark'],
  components: { dropdown },
  data () {
    return {
      showNav: false,
      q: '',
      active: false
    }
  },
  watch: {
    '$route' () {
      this.showNav = false
    }
  },
  computed: {
    ...mapGetters(['isLogged'])
  },
  methods: {
    login () {
      this.$buefy.modal.open({
        component: Login,
        parent: this,
        hasModalCard: true
      })
    },
    search () {
      this.$router.push({ name: 'search.keyword', query: { q: this.q } })
    },
    isNotLogged () {
      return !this.isLogged
    }
  }
}
</script>
<style scoped>
#navbarMenu {
  background-color: #192532;
}
</style>